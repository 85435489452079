import  { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Issuance from "../../pages/Issuance/Issuance";
import CoverPricesTable from "../../../../common/components/Table/CoverpricesTable";
import { fetcher } from "../../../../services/fetcher";
// import { mutate } from "swr";
import { errorNotifications, successNotifications } from "../../../../utils/handleNotifications";
import { useNavigate } from "react-router-dom";
import { Paper, Group, Checkbox, Title, Button, TextInput, Image, FileInput, Table, SimpleGrid, Divider } from "@mantine/core";
import classes from "./BulkIssuance.module.css";
import useSWR from "swr";
const endpoint = "issuance/upload/";

const BulkIssuance = () => {
  // const { control } = useForm();
  const [issueAs, setIssueAs] = useState("User");
  const [opened, setOpened] = useState(false);
  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null); 
  const [formData, setFormData] = useState(null);
  const [details, setDetails] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const [discountDetails, setDiscountDetails] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const tripSummaryRef = useRef(null);

  const { handleSubmit, control, getValues, setValue  } = useForm({
    defaultValues: {
      csv_file: null,
      is_operator: selectedOperator ? true : false,
      is_client: selectedOperator ? false : true,
      coupon_code: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.userProfile);
  // const userType = useSelector((state) => state.auth.user_type);

  const role = useSelector((state) => state.auth.role);
  console.log(role,'role');

  useEffect(() => {
    console.log("Current Selected Operator:", selectedOperator);
    setValue("is_operator", selectedOperator ? true : false);
    setValue("is_client", selectedOperator ? false : true);
  }, [selectedOperator, setValue]);

  const pricingEndpoint = `cover-api/customize-covers/${selectedCover?.value}/pricing/issuance/`;

  useEffect(() => {
  }, [selectedCover]);

  const { data} = useSWR(selectedCover ? pricingEndpoint : null, fetcher);
 
  // if (selectedCover){
  //   const { data, error } = useSWR(pricingEndpoint, fetcher);

  // }
  const issueCoverMutation = async (travellers, createTrip = false) => {
    const url = selectedOperator
      ? `${endpoint}?id=${selectedCover?.value}&cover_type=customize-covers&operator=${
          selectedOperator
        }${createTrip ? "&create_trip" : ""}`
      : `${endpoint}?id=${selectedCover?.value}&cover_type=customize-covers${
          createTrip ? "&create_trip" : ""
        }`;

    try {
      const response = await fetcher( url, "POST", travellers, {
        "Content-Type": "multipart/form-data",
      });
      return response;
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
      throw error;
    }
  };
  // const issueCoverMutation = async (travellers) => {
  //   const url = selectedOperator
  //     ? `${endpoint}?id=${selectedCover?.value}&cover_type=customize-covers&operator=${selectedOperator.id}`
  //     : `${endpoint}?id=${selectedCover?.value}&cover_type=customize-covers`;
  
  //   try {
  //     const response = await fetcher(url, "POST", travellers, {
  //       "Content-Type": "multipart/form-data",
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     errorNotifications(error.response.data, error);
  //     throw error;
  //   }
  // };
  

  const handleDiscountAPI = async (couponCode) => {
    const discountPayload = {
      user_id: user.id,
      coupon_code: couponCode,
      original_amount: details?.total_price || 0,
    };

    try {
      const response = await fetcher("issuance/apply-coupon/", "POST", discountPayload);
      setDiscountDetails(response);
      setDetails((prevDetails) => ({
        ...prevDetails, // Copy the existing details
        total_price: response.final_amount, // Update the total_price
      }));
      setIsCouponApplied(true);
      successNotifications("Discount applied successfully!");
    } catch (error) {
      console.error(error);
      errorNotifications("Failed to apply discount. Please check the coupon code.");
    }
  };  

  const onSubmit = async (payload) => {
    // console.log("Form submitted with payload:", payload);
    setIsSubmitting(true);

    const newFormData = new FormData();

    const isClient = payload.is_client ? "True" : "False";
    const isOperator = payload.is_operator ? "True" : "False";

    newFormData.append("csv_file", payload.csv_file);
    newFormData.append("Is_client", isClient);
    newFormData.append("is_operator", isOperator);
    // newFormData.append("booking_no", payload.booking_no);

    try {
      const response = await issueCoverMutation(newFormData);
      setDetails(response);
      setFormData(newFormData);
      setOpened(true);
      //  Apply discount if coupon code is provided
       if (payload.coupon_code) {
        await handleDiscountAPI(payload.coupon_code);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      // Clone formData and append additional fields
      const finalPayload = new FormData();
      for (const [key, value] of formData.entries()) {
        finalPayload.append(key, value);
      }
  
      if (discountDetails) {
        finalPayload.append("discounted_amount", discountDetails.discounted_amount || 0);
        finalPayload.append("total_price", discountDetails.final_amount || 0);
      }
  
      // Debugging payload
      // for (const [key, value] of finalPayload.entries()) {
      //   // console.log(`${key}: ${value}`);
      // }
  
      // Call mutation
      // const response = await issueCoverMutation(finalPayload, true);
      // console.log("Mutation Response:", response);
    
      navigate("/issuance/user-specific-policy");
      
      successNotifications("Success! Uploaded with trip creation.");
    } catch (error) {
      console.error("Confirm Button Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownload = () => {
    const fileUrl = "/samplecsv/spreadsheet.csv";
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = "spreadsheet.csv";
    a.click();
  };
  const handleCsvPreview = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;
      const rows = text.split(/\r?\n/);
      const headers = rows[0].split(",");

      const dataRows = rows.slice(1);

      const parsedData = dataRows
        .filter((row) => row.trim() !== "")
        .map((row) => {
          const values = row.split(",");
          let rowObj = {};
          headers.forEach((header, index) => {
            rowObj[header.trim()] = values[index].trim();
          });
          return rowObj;
        });

      setCsvPreview(parsedData);
    };

    reader.readAsText(file);
  };
  const tripSummaryScroll = async () =>{
    setTimeout(() => {
      tripSummaryRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 300); 
  }

  return (
    <>
      <Issuance 
        selectedValue={issueAs} 
        onChange={(e) => setIssueAs(e.target.value)} 
        selectedCover={selectedCover} 
        setSelectedCover={setSelectedCover} 
        policyType="bulk"
        selectedOperator={selectedOperator} // Pass selectedOperator
        setSelectedOperator={setSelectedOperator} // Capture selected operatorselectedOperator, setSelectedOperator
      />
      {selectedCover  &&(
        <Paper my={"lg"} shadow="xl" className={classes.bulkIssuancePaper}>
          <CoverPricesTable
            pricingEndpoint={pricingEndpoint}
            selectedCover={selectedCover?.label}
          />
        </Paper>
      )}
      {selectedCover && data?.length > 0 && (
        <div className={classes.emailPreference}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper my={"lg"} p={"lg"}>
              <Title order={5} mb={"lg"}>
                Email Preferences
              </Title>
              <Group>
                <Controller
                  name="is_client"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      radius="sm"
                      label="Customer"
                      onChange={field.onChange}
                      checked={field.value}
                    />
                  )}
                />

                <Controller
                  name="is_operator"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      radius="sm"
                      label="Operator"
                      onChange={field.onChange}
                      checked={field.value}
                    />
                  )}
                />
              </Group>
            </Paper>
            <Paper my={"lg"} p={"lg"}>
            <Group
              position="apart"
              align="center"
              style={{ justifyContent: "space-between" }}
            >
              <Title order={5} style={{ marginRight: "auto" }}>Upload CSV File</Title>
              <Group spacing="md" style={{ justifyContent: "flex-end", flexGrow: 1 }}>
              {/* {role?.some(r => ["Super Admin", "Issuance Team", "Admin"].includes(r)) && ( */}
              {/* {role?.some(r => ["Super Admin", "Issuance Team", "Admin"].includes(r)) && issueAs !== "Operator" && (
                <Controller
                  name="booking_no"
                  control={control}
                  rules={{
                    required: "Booking number is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label=""
                      placeholder="Enter Booking No."
                      error={error?.message}
                    />
                  )}
                />
              )} */}
              <Button
                variant="outline"
                onClick={handleDownload}
                leftSection={<Image src="/images/svg/download.svg" size={14} />}
              >
                CSV Sample File
              </Button> 
              </Group> 
            </Group>  
            <Controller
              name="csv_file"
              control={control}
              rules={{ required: "Traveller CSV file required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  {...field}
                  label=""
                  placeholder=""
                  accept=".csv"
                  classNames={{
                    input: classes.fileInput,
                    placeholder: classes.placeholder,
                  }}
                  error={error?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    handleCsvPreview(e); // Call CSV preview on file select
                  }}
                />
              )}
            />

            {/* Display CSV Preview */}
            {csvPreview.length > 0 && (
              <div className={classes.csvPreviewContainer}>
                <Table
                  my={"lg"}
                  highlightOnHover
                  className={classes.csvPreviewTable}
                >
                  <thead>
                    <tr>
                      {Object.keys(csvPreview[0]).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvPreview.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, idx) => (
                          <td key={idx}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            <Group justify="center" mt="lg">
              <Button
                type="submit"
                variant="outline"
                my={"lg"}
                loading={isSubmitting}
                loaderProps={{ type: "dots" }}
                onClick={tripSummaryScroll}
              >
                Submit
              </Button>
            </Group>
          </Paper>
          </form>
        </div>
      )}

      {opened && (
        <Paper my={"lg"} p={"lg"} shadow="xl" ref={tripSummaryRef}>
          <Title className={classes.coverpriceTitle}>Trip Summary</Title>
          <Divider mt="lg" mb="xl" />
          <SimpleGrid
            cols={{ base: 1, xs: 2, sm: 1, lg: 2 }}
            spacing={{ base: 10, xs: "xl", sm: "xl" }}
          >
            <TextInput
              label="No. of pax"
              value={details?.traveller_count || ""}
              readOnly
            />
            <TextInput
              label="Total Price"
              value={details?.total_price || ""}
              readOnly
            />  
            <TextInput
              label="Wallet Amount"
              value={details?.user_wallet || ""}
              readOnly
            />
           {discountDetails && (
              <>
                <TextInput
                  label="Original Amount"
                  value={discountDetails.original_amount || ""}
                  readOnly
                />
                <TextInput
                  label="Discounted Amount"
                  value={discountDetails.discounted_amount || ""}
                  readOnly
                />
                <TextInput
                  label="Final Amount"
                  value={discountDetails.final_amount || ""}
                  readOnly
                />
              </>
            )}
            <Group>
              <Controller
                name="coupon_code"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    label="Coupon Code"
                    {...field}
                    placeholder="Enter coupon code"
                    error={error?.message}
                  />
                )}
              />
              <Button
                onClick={async () => {
                  const couponCode = getValues("coupon_code");
                  if (!couponCode) {
                    errorNotifications("Please enter a valid coupon code.");
                    return;
                  }
                  await handleDiscountAPI(couponCode); // Call the discount API
                }}
                loading={isSubmitting}
                disabled={isCouponApplied} 
                mt={"xl"}
              >
                Apply Coupon
              </Button>
            </Group>

            {selectedOperator && (
              <>
                <TextInput
                  label="Commission Type"
                  value={details?.commission_type || ""}
                  readOnly
                />
                <TextInput
                  label="Commission Value"
                  value={details?.commission_value || ""}
                  readOnly
                />
                <TextInput
                  label="Commission"
                  value={details?.commission_price || ""}
                  readOnly
                />
              </>
            )}
          </SimpleGrid>
          <Group justify="center" mt={"xl"}>
            <Button
              onClick={handleConfirm}
              loading={isSubmitting}
              loaderProps={{ type: "dots" }}
            >
              Confirm
            </Button>
            <Button onClick={() => setOpened(false)} variant="outline">
              Cancel
            </Button>
          </Group>
        </Paper>
      )}
    </>
  );
};

export default BulkIssuance;