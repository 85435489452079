import { useState, useEffect } from "react";
import useSWR from "swr";
import { Radio, Paper, Group, Button, Center,Checkbox } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import GetYourQuoteBooking from "../GetYourQuoteBooking/GetYourQuoteBooking";
import PriceCard from "../../../issuance/components/PriceCard/PriceCard";
// import TravelerInfo from "../../../issuance/containers/SingleIssuance/TravelerInfo";
import { errorNotifications } from "../../../../utils/handleNotifications";
import QuoteLink from "../../../issuance/containers/QuoteLink/QuoteLink";
import CreateBooking from "../../pages/CreateBooking/CreateBooking";
import BookingForm from "../BookingForm/BookingForm";

const SingleBookingForm = () => {
  const [issueAs, setIssueAs] = useState("User");
  const [selectedOperator, setSelectedOperator] = useState(null); // Store selected operator
  const [quoteUrl, setQuoteUrl] = useState("");
  const [coverOption, setCoverOption] = useState("");
  const [showQuoteLink, setShowQuoteLink] = useState(false);
  // const [showTravelerForm, setShowTravelerForm] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingFormData, setBookingFormData] = useState({});
  const [makePayment, setMakePayment] = useState(false); // Checkbox state


  const { data: quoteData } = useSWR(quoteUrl ? quoteUrl : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    dedupingInterval: 2000,
    onError: (err) => {
      errorNotifications(err.response.data.message || "An error occurred.");
    },
  });

  const handleUrlReady = (url) => {
    setQuoteUrl(url);
  };

  const getFilteredCovers = (covers, totalPrice, currency) => {
    return Object.keys(covers)
      .filter((coverType) => covers[coverType].length > 0)
      .map((coverType) => ({
        type: coverType,
        price: totalPrice[coverType],
        currency: currency,
      }));
  };

  let filteredCovers = [];
  if (quoteData) {
    filteredCovers = getFilteredCovers(quoteData.covers, quoteData.total_price, quoteData.currency);
  }

  // const handleContinue = () => {
  //   setShowQuoteLink(false);
  //   setShowTravelerForm(true);
  // };

  // useEffect(() => {
  //   console.log("Updated BookingForm Data:", bookingFormData);
  // }, [bookingFormData]);
  useEffect(() => {
    setBookingFormData((prev) => ({
      ...prev,
      make_payment: makePayment, 
    }));
  }, [makePayment]);

  // const handleTraveler = (travelerData) => {
  //   setBookingFormData((prev) => ({
  //     ...prev,
  //     travelers: travelerData, // Assuming travelerData is an array of traveler details
  //   }));
  // };

  useEffect(() => {
    console.log("Selected Operator in SingleBookingForm:", selectedOperator);
  }, [selectedOperator]);
  
  
  return (
    <div>
      <h2>Single Booking</h2>
      <CreateBooking 
        selectedValue={issueAs} 
        onChange={(e) => setIssueAs(e.target.value)} 
        policyType="single" 
        setSelectedCover={() => {}} 
        selectedOperator={selectedOperator} // Pass selectedOperator
        setSelectedOperator={setSelectedOperator} // Capture selected operator
      />
      
      {(issueAs === "User" || (issueAs === "Operator" && selectedOperator)) && (
        <>
        <GetYourQuoteBooking onUrlReady={handleUrlReady} selectedOperator={selectedOperator} />
          {quoteData && (
            <Paper p={"xl"} my={"lg"}>
              {filteredCovers.length > 0 ? (
                <>
                  <Radio.Group
                    value={coverOption}
                    onChange={(value) => {
                      setCoverOption(value);
                      // setShowTravelerForm(false);
                      setShowQuoteLink(false);
                    }}
                    name="coverSelection"
                    label="Select your cover"
                    withAsterisk
                  >
                    <Group mt={"xl"}>
                      {filteredCovers.map((cover, index) => (
                        <Radio key={index} value={cover.type} label={<PriceCard cover={cover} currency={cover.currency} index={index} />} />
                      ))}
                    </Group>
                  </Radio.Group>

                  {/* <BookingForm  hideFields={["csv_file", "plan_name", "client_or_operator", "submit"]}   onChange={(data) => setBookingFormData(data)}
                  /> */}
                  {/* <BookingForm 
                    hideFields={["csv_file", "plan_name", "client_or_operator", "submit"]}
                    onFormChange={(data) => {
                      console.log("BookingForm Data Updated:", data); // Debugging
                      setBookingFormData(data);
                    }}
                  /> */}
                  <BookingForm 
                    hideFields={["csv_file", "plan_name", "client_or_operator", "submit"]}
                    onFormChange={(data) => {
                      setBookingFormData((prev) => ({ ...prev, ...data }));
                    }}
                  />
                  {/* Checkbox for payment */}
                  <div style={{ marginTop: "1rem" }}> 
                    <Checkbox 
                      label="Make payment or not" 
                      checked={makePayment} 
                      onChange={(event) => setMakePayment(event.currentTarget.checked)}
                    />
                  </div>
                  
                  {coverOption && (
                    <Center mt={"lg"}>
                      <Group mt="xl">
                        <Button variant="outline" onClick={() => setShowQuoteLink(true)}>Generate Link</Button>
                        {/* or
                        <Button variant="" onClick={handleContinue}>Continue</Button> */}
                      </Group>
                    </Center>
                  )}
                </>
              ) : (
                <p>No covers available.</p>
              )}
            </Paper>
          )}

          {/* {quoteData && coverOption && showTravelerForm && (
            <TravelerInfo 
              data={quoteData} 
              coverType={coverOption} 
              isSubmitting={isSubmitting} 
              handleTraveler={handleTraveler} 
             />
          )} */}

          {/* {quoteData && coverOption && showQuoteLink && (
            <QuoteLink quoteData={quoteData} coverOption={coverOption} bookingFormData={bookingFormData} 
            />
          )} */}
          {quoteData && coverOption && showQuoteLink && (
            <>
              <QuoteLink 
                quoteData={quoteData} 
                coverOption={coverOption} 
                bookingFormData={bookingFormData} 
              />
            </>
          )}

        </>
      )}
    </div>
  );
};

export default SingleBookingForm;
