import { useOutletContext } from "react-router-dom";
import { Card, Text, Title, Group, SimpleGrid, Button, Image } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import classes from "./StaticEmailTemplates.module.css";

const StaticEmailTemplates = () => {
  const { data, error, isLoading } = useOutletContext();
  const navigate = useNavigate();

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading email templates.</p>;
  if (!data || !Array.isArray(data)) return <p>No email templates available.</p>;

  const staticTemplates = data.filter(template => template.template_type === "static");

  return (
    <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, md: 2 }} spacing="lg" style={{ padding: "16px" }}>
      {staticTemplates.map((template) => (
        <Card key={template.id} shadow="sm" padding="lg">
          <Title order={3} className={classes.templateHeading} style={{ marginBottom: "8px" }}>
            {template.name}
          </Title>
          <Text className={classes.templateSubject}>
            <strong style={{ color: "#333" }}>Subject:</strong> {template.subject}
          </Text>
          <Text className={classes.templateBody}>
            <strong style={{ color: "#333" }}>Body:</strong> {template.body}
          </Text>
          <div className={classes.container}>
            <Image
                src={`${import.meta.env.VITE_API_ENDPOINT}${template.template_screenshot}`}
                alt="Static Template Preview"
                className={classes.image}
            />
          </div>
          {template.attachment && (
            <Text style={{ marginTop: "12px", color: "#FF5C00", cursor: "pointer" }}>
              {template.attachment.split('/').pop()}
            </Text>
          )}
          <Group justify="center" style={{ marginTop: "12px" }}>
            <Button
              size="sm"
              variant="outline"
              onClick={() => navigate(`/edit/${template.id}?templateType=${template.template_type}`)}
            >
              Edit
            </Button>
          </Group>
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default StaticEmailTemplates;