import  { useState } from "react";
import CreateBooking from "../../pages/CreateBooking/CreateBooking";
import BookingForm from "../BookingForm/BookingForm";
import { uploadFetcher } from "../../../../services/fetcher";
import { mutate } from "swr";
import { errorNotifications, successNotifications } from "../../../../utils/handleNotifications";
import {  Paper } from "@mantine/core";
import {bookings} from '../../../../api/endpoints';



const BulkBookingForm = () => {
  const [issueAs, setIssueAs] = useState("User");
  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null); 
  // const [modalOpened, setModalOpened] = useState(false);

  // Function to submit booking data
  // console.log("url...", bookings);
  const addBookingMutation = async (booking) => {
    console.log(booking,'booking');
    try {
      await uploadFetcher(bookings, "POST", booking);
      successNotifications("Success! Booking added");
      mutate(bookings);
      // setModalOpened(false);
    } catch (error) {
      errorNotifications(error.response?.data || "Something went wrong", error);
    }
  };

  return (
    <div>
      <h2>Bulk Booking</h2>
      <CreateBooking 
        selectedValue={issueAs} 
        onChange={(e) => setIssueAs(e.target.value)} 
        selectedCover={selectedCover} 
        setSelectedCover={setSelectedCover} 
        policyType="bulk"
        selectedOperator={selectedOperator} // Pass selectedOperator
        setSelectedOperator={setSelectedOperator} // Capture selected operatorselectedOperator, setSelectedOperator
      />
      
      {/* BookingForm appears only when a cover is selected */}
      {/* {selectedCover && <BookingForm onSubmit={addBookingMutation} selectedCover={selectedCover}/>} */}
      {selectedCover && (
        <Paper shadow="md" p="lg" mt="lg" mb="lg">
          <BookingForm onSubmit={addBookingMutation} selectedCover={selectedCover} />
        </Paper>
      )}

    </div>
  );
};

export default BulkBookingForm;
