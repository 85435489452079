import AddUserForm from "../../forms/AddUserForm/AddUserForm";
import useGroupsAndDepartments from "../../hooks/useGroupsAndDepartments/useGroupsAndDepartments";
import { useNavigate } from "react-router-dom";

import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import { addUserOptions } from "../../helpers/userSWROptions";

import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";

const endpoint = "auth-api/user-create/";

const AddUsersPage = () => {
  const navigate = useNavigate();
  const { mutate } = useSWR(endpoint, fetcher);
  const { fields, loadingFields } = useGroupsAndDepartments();

  const addUserMutation = async (newUser) => {
    try {
      await mutate(
        fetcher(`${endpoint}`, "POST", newUser),
        addUserOptions(newUser)
      );
      successNotifications("Success! User new item.");
      navigate(-1);
    } catch (err) {
      errorNotifications(err.response?.data, err);
    }
  };

  const handleSubmit = (payload) => {
    addUserMutation(payload);
  };

  return (
    <>
      <AddUserForm
        groups={loadingFields ? [] : fields.roles}
        departments={loadingFields ? [] : fields.departments}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AddUsersPage;
