import CoverEnquiryList from "../features/cover-enquiry/pages/CoverEnquiryList/CoverEnquiryList";
import PaymentList from "../features/payment/pages/PaymentList/PaymentList";
import PromotionList from "../features/promotion/pages/PromotionList/Promotion";
import FaqList from "../features/faq/pages/FaqList/FaqList";

const WebsiteRoutes = [
  {
    path: "website",
    children: [
        { path: "cover-enquiries", element: <CoverEnquiryList /> },
        { path: "website-payments", element: <PaymentList /> },
        { path: "promotions", element: <PromotionList /> },
        { path: "faqs", element: <FaqList /> },
    ],
  },
];

export default WebsiteRoutes;
