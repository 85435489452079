export const BookingStatus = [
  { label: "OPEN", value: "OPEN" },
  { label: "CLOSED", value: "CLOSED" },
  { label: "CANCELED", value: "CANCELED" },
];

export const BookingPriority = [
  { label: "LOW", value: "LOW" },
  { label: "MEDIUM", value: "MEDIUM" },
  { label: "HIGH", value: "HIGH" },
];

export const PaymentStatus = [
  { label: "PENDING", value: "PENDING" },
  { label: "RECEIVED", value: "RECEIVED" },
  { label: "PAID_THROUGH_WEB", value: "PAID_THROUGH_WEB" },
];

export const DocumentStatus = [
  { label: "IN_PROCESS", value: "IN_PROCESS" },
  { label: "SENT_ON_WATSAPP", value: "SENT_ON_WATSAPP" },
  { label: "SENT_ON_EMAIL", value: "SENT_ON_EMAIL" },
  { label: "SENT_VIA_WEBSITE", value: "SENT_VIA_WEBSITE" },
  { label: "NOT_REQUIRED", value: "NOT_REQUIRED" },
  { label: "ON_HOLD", value: "ON_HOLD" },
  { label: "NOT_PROCESS", value: "NOT_PROCESS" },
  { label: "HARD_DRIVE", value: "HARD_DRIVE" },
];

export const BookingReceived = [
  { label: "WHATSAPP", value: "WHATSAPP" },
  { label: "G_FORM", value: "G-FORM" },
  { label: "EMAIL", value: "EMAIL" },
  { label: "WEBSITE", value: "WEBSITE" },
  { label: "NEPAL_OFFICE", value: "NEPAL_OFFICE" },
];
