import useSWR from "swr";
import { useForm } from "react-hook-form";
import { fetcher } from "../../../../services/fetcher";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLoader from "../../../../common/components/Loader/Loader";
import DataTable from "../../../../common/components/DataTable/DataTable";
import FormDataTable from "../../../../common/components/FormDataTable/FormDataTable";
import CommissionStats from "../../components/CommissionStats/CommissionStats";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import { USER_TYPES } from "../../../../utils/userTypes";
import { Tabs } from "@mantine/core";

const endpoint = "operator/plan-commission/";
const tripsEndpoint = "operator/commission-summaries/";
const commissionEndpoint = "operator/operator-commissions/";

const Commission = () => {
  const outletContext = useOutletContext();
  const operator = outletContext ? outletContext.operator : null;
  const { user_type, role } = useSelector((state) => state.auth);

  const { control, handleSubmit } = useForm();

  const operatorEmail = operator ? operator.operator.email : null;
  const planCommissionEndpoint = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${endpoint}?email=${operatorEmail}`
    : endpoint;

  const commissionSummaryEndpoint = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${tripsEndpoint}?operator_email=${operatorEmail}`
    : tripsEndpoint;

  const OperatorCommissionEndpoint = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${commissionEndpoint}?operator_email=${operatorEmail}`
    : commissionEndpoint;

  const {
    data: covers,
    // error: coversError,
    isLoading: isCoversLoading,
    mutate: coversMutate,
  } = useSWR(planCommissionEndpoint, fetcher, {
    dedupingInterval: 60000,
  });

  const {
    data: trips,
    // error: tripsError,
    isLoading: isTripsLoading,
  } = useSWR(commissionSummaryEndpoint, fetcher, {
    dedupingInterval: 60000,
  });

  const {
    data: commission,
    // error: commissionError,
    // isLoading: isCommissionLoading,
  } = useSWR(OperatorCommissionEndpoint, fetcher, {
    dedupingInterval: 60000,
  });

  const onSubmitRow = (
    row,
    fieldKeys = ["commission_type", "commission_value"]
  ) => {
    handleSubmit((formData) => {
      const data = {};
      const coverID = row.id;

      fieldKeys.forEach((key) => {
        data[key] = formData[`${key}_${row.id}`];
      });

      data.id = coverID;

      editCommissionMutation(data);
    })();
  };

  const editCommissionMutation = async (payload) => {
    try {
      await fetcher(
        `${endpoint}${payload.id}/?email=${operator.operator.email}`,
        "PATCH",
        payload
      );
      await coversMutate();
      successNotifications("Success! Commission Added .");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    }
  };

  const coversConfig = {
    fields: [
      { key: "plan_name", label: "Name", isInput: false },
      {
        key: "commission_type",
        label: "Commission Type",
        isSelect: true,
        options: ["flat", "percentage"],
      },
      { key: "commission_value", label: "Commission", isInput: true },
      { key: "assigned_by", label: "Assign By", isInput: false },
      // { key: "assigned_date", label: "Assign At", isInput: false },
    ],
  };

  const tripsConfig = {
    fields: [
      { key: "trip_details.cover_name", label: "Name" },
      { key: "trip_details.no_of_pax", label: "No. of Pax" },
      { key: "commission_amount", label: "Commission Amount" },
      { key: "trip_details.total_amount", label: "Total Amount" },
    ],
  };

  return (
    <>
      {commission && <CommissionStats data={commission} />}

      <Tabs defaultValue="covers" my={"lg"}>
        <Tabs.List>
          <Tabs.Tab value="covers">Covers wise commission</Tabs.Tab>
          <Tabs.Tab value="trips">Trips wise commission</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="covers">
          {isCoversLoading ? (
            <MainLoader />
          ) : (
            <FormDataTable
              heading={"Covers"}
              config={coversConfig}
              data={covers.results}
              onSubmitRow={onSubmitRow}
              control={control}
              userType={
                role.includes(USER_TYPES.SUPERUSER)
                  ? USER_TYPES.SUPERUSER
                  : null
              }
            />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="trips">
          {isTripsLoading ? (
            <MainLoader />
          ) : (
            <DataTable
              heading={"Trips"}
              config={tripsConfig}
              data={trips.results}
            />
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default Commission;
