// import { useSelector } from "react-redux";

// const WithPermission = (props) => {
//   const { roleRequired, children } = props;
//   const { isAuthenticated, roles } = useSelector((state) => state.auth);

//   // Allow access to the dashboard for all roles
//   if (isAuthenticated && roleRequired.length === 0) {
//     return <>{children}</>;
//   }

//   return (
//     isAuthenticated &&
//     roles.find((role) => roleRequired?.includes(role)) && <>{children}</>
//   );
// };

// export default WithPermission;
import { useSelector } from "react-redux";

const WithPermission = (props) => {
  const { roleRequired = [], children } = props; 
  const { isAuthenticated, role } = useSelector((state) => state.auth); 

  if (!Array.isArray(roleRequired)) {
    console.error("roleRequired should be an array, but got:", roleRequired);
    return null; // Return nothing if roleRequired is invalid
  }

  if (isAuthenticated && roleRequired.length === 0) {
    return <>{children}</>;
  }

  return isAuthenticated && roleRequired.includes(role) ? <>{children}</> : null;
};

export default WithPermission;
