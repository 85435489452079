// import { useState, useRef, useEffect } from "react";
// import useSWR from "swr";
// import { Radio, Paper, Group, Button, Center, Grid, TextInput } from "@mantine/core";
// import { fetcher } from "../../../../services/fetcher";
// import { useNavigate } from "react-router-dom";
// import GetYourQuote from "../GetYourQuote/GetYourQuote";
// import PriceCard from "../../components/PriceCard/PriceCard";
// import { useSelector } from "react-redux";
// import TravelerInfo from "./TravelerInfo";
// import {
//   errorNotifications,
//   successNotifications,
// } from "../../../../utils/handleNotifications";
// import QuoteLink from "../QuoteLink/QuoteLink";
// import dayjs from "dayjs";

// const endpoint = "issuance/create-policy/";

// const SingleIssuance = ({ operator }) => {
//   const [quoteUrl, setQuoteUrl] = useState("");
//   const [coverOption, setCoverOption] = useState("");
//   const [showQuoteLink, setShowQuoteLink] = useState(false);
//   const [showTravelerForm, setShowTravelerForm] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [couponCode, setCouponCode] = useState("");
//   const [originalAmount, setOriginalAmount] = useState(null);
//   const [discountedAmount, setDiscountedAmount] = useState(null);
//   const [isCouponApplying, setIsCouponApplying] = useState(false);
//   const [isCouponApplied, setIsCouponApplied] = useState(false);

//   const navigate = useNavigate();

//   const { data: quoteData } = useSWR(quoteUrl ? quoteUrl : null, fetcher, {
//     revalidateOnFocus: false, // Disable revalidation when the window regains focus
//     revalidateOnReconnect: true, // Revalidate when the browser regains a network connection
//     dedupingInterval: 2000,
//     onError: (err) => {
//       const errorData = err.response.data;
//       let errorMessage = "An error occurred.";

//       if (errorData.non_field_errors && errorData.non_field_errors.length > 0) {
//         errorMessage = errorData.non_field_errors.join(", ");
//       } else if (errorData.message) {
//         errorMessage = errorData.message;
//       }

//       successNotifications(errorMessage);
//     },
//   });

//   const userId = useSelector((state) => state.userProfile.id); // Get user ID from Redux store

//   const applyCoupon = async () => {
//     if (!couponCode) {
//       errorNotifications("Please enter a valid coupon code.");
//       return;
//     }

//     setIsCouponApplying(true);

//     try {
//       const response = await fetcher("issuance/apply-coupon/", "POST", {
//         user_id: userId,
//         coupon_code: couponCode,
//         original_amount: quoteData.total_price[coverOption], // Pass selected cover's amount
//       });

//       setOriginalAmount(response.original_amount);
//       setDiscountedAmount(response.discounted_amount);
//       successNotifications(`Coupon applied! Discounted Amount: ${response.discounted_amount}`);
//       setIsCouponApplied(true);
//     } catch (error) {
//       errorNotifications(error.response.data.message || "Failed to apply coupon.");
//     } finally {
//       setIsCouponApplying(false);
//     }
//   };

//   const paperRef = useRef(null);
//   const travelerInfoRef = useRef(null);
//   const quoteLinkRef = useRef(null);

//   useEffect(() => {
//     if (quoteData) {
//       paperRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [quoteData]);

//   useEffect(() => {
//     if (showTravelerForm) {
//       travelerInfoRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [showTravelerForm]);

//   useEffect(() => {
//     if (showQuoteLink) {
//       quoteLinkRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [showQuoteLink]);

//   const handleUrlReady = (url) => {
//     setQuoteUrl(url);
//   };

//   const getFilteredCovers = (covers, totalPrice, currency) => {
//     return Object.keys(covers)
//       .filter((coverType) => covers[coverType].length > 0)
//       .map((coverType) => ({
//         type: coverType,
//         price: totalPrice[coverType],
//         currency: currency,
//       }));
//   };

//   let filteredCovers = [];
//   if (quoteData) {
//     filteredCovers = getFilteredCovers(
//       quoteData.covers,
//       quoteData.total_price,
//       quoteData.currency
//     );
//   }

//   const issuePolicyMutation = async (policy) => {
//     setIsSubmitting(true);
//     const createPolicy = operator
//       ? `${endpoint}?operator=${operator.id}`
//       : endpoint;

//     try {
//       await fetcher(createPolicy, "POST", policy, {
//         "content-type": "multipart/form-data",
//       });
//       navigate("/issuance/policies/");
//       successNotifications("Success! Policy Issue");
//     } catch (error) {
//       errorNotifications(error.response.data, error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const handleTraveler = (
//     allTravelersData,
//     operator_id = null,
//     operator_name = null,
//     operator_phone = null
//   ) => {
//     const formData = new FormData();

//     const travellers = allTravelersData.map((traveler) => {
//       return Object.entries({
//         cover_pricing_id: traveler.cover_pricing_id,
//         indian_resident: traveler.indian_resident,
//         first_name: traveler.first_name,
//         last_name: traveler.last_name,
//         email: traveler.email,
//         gender: traveler.gender,
//         nationality: traveler.nationality,
//         phone_number: traveler.phone_number,
//         dob: traveler.dob ? dayjs(traveler.dob).format("YYYY-MM-DD") : "",
//         is_primary: traveler.is_primary || false,
//         passport_number: traveler.passport_number || "",
//         emergency_email: traveler.emergency_email || "",
//         emergency_name: traveler.emergency_name || "",
//         emergency_contact: traveler.emergency_contact || "",
//         passport_back: traveler.passport_back || null,
//         passport_front: traveler.passport_front || null,
//         aadhar_card_front: traveler.aadhar_card_front || null,
//         aadhar_card_back: traveler.aadhar_card_back || null,
//         flight_ticket: traveler.flight_ticket || null,
//         residence_proof: traveler.residence_proof || null,
//       }).reduce((acc, [key, value]) => {
//         if (value !== null && value !== "") {
//           acc[key] = value;
//         }
//         return acc;
//       }, {});
//     });

//     const payload = {
//       traveller_from: quoteData.quote_data.travelling_from || "",
//       traveller_to: quoteData.quote_data.travelling_to || "",
//       coverage_start_date: quoteData.quote_data.start_date || "",
//       coverage_end_date: quoteData.quote_data.end_date || "",
//       no_of_pax: quoteData.quote_data.age_fields.length || "",
//       tour_operator_name: operator?.id ? operator.label : operator_name,
//       operator_phone_number: operator_phone && operator_phone,
//       operator_profile_id: operator?.id ? operator?.id : operator_id,
//       operator_profile_name: operator?.id ? operator.label : operator_name,
//       original_amount: originalAmount, 
//       discounted_amount: discountedAmount,
//       travellers: travellers,
//     };

//     // Append payload data to FormData
//     for (const key in payload) {
//       if (Object.prototype.hasOwnProperty.call(payload, key)) {
//         if (key === "travellers") {
//           payload[key].forEach((traveler, idx) => {
//             for (const field in traveler) {
//               if (Object.prototype.hasOwnProperty.call(traveler, field)) {
//                 formData.append(`travellers[${idx}].${field}`, traveler[field]);
//               }
//             }
//           });
//         } else {
//           formData.append(key, payload[key]);
//         }
//       }
//     }

//     issuePolicyMutation(formData);
//   };

//   const handleGenerateLink = () => {
//     setShowQuoteLink(true);
//     setShowTravelerForm(false);
//   };

//   const handleContinue = () => {
//     setShowQuoteLink(false);
//     setShowTravelerForm(true);
//   };
//   console.log("Component Render Ho Raha Hai!");

//   return (
//     <>
//       <GetYourQuote onUrlReady={handleUrlReady} operator={operator} />

//       {quoteData && (
//         <Paper ref={paperRef} p={"xl"} my={"lg"}>
//           {filteredCovers.length > 0 ? (
//             <>
//               <Radio.Group
//                 value={coverOption}
//                 onChange={(value) => {
//                   setCoverOption(value);
//                   setShowTravelerForm(false);
//                   setShowQuoteLink(false);
//                 }}
//                 name="coverSelection"
//                 label="Select your cover"
//                 withAsterisk
//               >
//                 <Group mt={"xl"}>
//                   {filteredCovers.map((cover, index) => (
//                     <Radio
//                       key={index}
//                       value={cover.type}
//                       label={
//                         <PriceCard
//                           cover={cover}
//                           currency={cover.currency}
//                           index={index}
//                         />
//                       }
//                     />
//                   ))}
//                 </Group>
//               </Radio.Group>  
//               {coverOption && (
//                 <>
//                   <Grid span={{ base: 12, md: 6, lg: 3 }}>
//                     <Group mt={"xl"} ml={"sm"}>
//                       <TextInput
//                         type="text"
//                         placeholder="Enter coupon code"
//                         value={couponCode}
//                         onChange={(e) => setCouponCode(e.target.value)}
//                         // style={{ flexGrow: 1 }}
//                       />
//                       <Button onClick={applyCoupon} loading={isCouponApplying} disabled={isCouponApplying || isCouponApplied}>
//                         Apply
//                       </Button>
//                     </Group>
//                   </Grid>

//                   {originalAmount !== null && discountedAmount !== null && (
//                     <Grid mt="md">
//                       <Grid.Col span={6}>
//                         <TextInput
//                           label="Original Amount"
//                           value={originalAmount}
//                           readOnly
//                         />
//                       </Grid.Col>
//                       <Grid.Col span={6}>
//                         <TextInput
//                           label="Discounted Amount"
//                           value={discountedAmount}
//                           readOnly
//                         />
//                       </Grid.Col>
//                     </Grid>
//                   )}

//                   <Center mt={"lg"}>
//                     <Group mt="xl">
//                       <Button variant="outline" onClick={handleGenerateLink}>
//                         Generate Link
//                       </Button>
//                       or
//                       <Button variant="" onClick={handleContinue}>
//                         Continue
//                       </Button>
//                     </Group>
//                   </Center>
//                 </>
//               )}      
//             </>
//           ) : (
//             <p>No covers available.</p>
//           )}
//         </Paper>
//       )}

//       {quoteData && coverOption && showTravelerForm && (
//         <div ref={travelerInfoRef}>
//           <TravelerInfo
//             data={quoteData}
//             operator={operator && operator}
//             coverType={coverOption}
//             handleTraveler={handleTraveler}
//             isSubmitting={isSubmitting}
//           />
//         </div>
//       )}

//       {quoteData && coverOption && showQuoteLink && (
//         <div ref={quoteLinkRef}>
//           <QuoteLink
//             quoteData={quoteData}
//             coverOption={coverOption}
//             isOperator={operator && operator}
//           />
//         </div>
//       )}
//     </>
//   );
// };

// export default SingleIssuance;

import { useState, useEffect } from "react";
import useSWR from "swr";
import { Radio, Paper, Group, Button, Center } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import GetYourQuoteBooking from "../../../booking/forms/GetYourQuoteBooking/GetYourQuoteBooking";
import PriceCard from "../../../issuance/components/PriceCard/PriceCard";
// import TravelerInfo from "../../../issuance/containers/SingleIssuance/TravelerInfo";
import { errorNotifications } from "../../../../utils/handleNotifications";
import QuoteLink from "../../../issuance/containers/QuoteLink/QuoteLink";
import Issuance from "../../pages/Issuance/Issuance";
// import BookingForm from "../BookingForm/BookingForm";

const SingleIssuance = () => {
  const [issueAs, setIssueAs] = useState("User");
  const [selectedOperator, setSelectedOperator] = useState(null); // Store selected operator
  const [quoteUrl, setQuoteUrl] = useState("");
  const [coverOption, setCoverOption] = useState("");
  const [showQuoteLink, setShowQuoteLink] = useState(false);
  // const [showTravelerForm, setShowTravelerForm] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [bookingFormData, setBookingFormData] = useState({});
  // const [makePayment, setMakePayment] = useState(false); // Checkbox state


  const { data: quoteData } = useSWR(quoteUrl ? quoteUrl : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    dedupingInterval: 2000,
    onError: (err) => {
      errorNotifications(err.response.data.message || "An error occurred.");
    },
  });

  const handleUrlReady = (url) => {
    setQuoteUrl(url);
  };

  const getFilteredCovers = (covers, totalPrice, currency) => {
    return Object.keys(covers)
      .filter((coverType) => covers[coverType].length > 0)
      .map((coverType) => ({
        type: coverType,
        price: totalPrice[coverType],
        currency: currency,
      }));
  };

  let filteredCovers = [];
  if (quoteData) {
    filteredCovers = getFilteredCovers(quoteData.covers, quoteData.total_price, quoteData.currency);
  }

  // const handleContinue = () => {
  //   setShowQuoteLink(false);
  //   setShowTravelerForm(true);
  // };

  // useEffect(() => {
  //   console.log("Updated BookingForm Data:", bookingFormData);
  // }, [bookingFormData]);
  // useEffect(() => {
  //   setBookingFormData((prev) => ({
  //     ...prev,
  //     make_payment: makePayment, 
  //   }));
  // }, [makePayment]);

  // const handleTraveler = (travelerData) => {
  //   setBookingFormData((prev) => ({
  //     ...prev,
  //     travelers: travelerData, // Assuming travelerData is an array of traveler details
  //   }));
  // };

  useEffect(() => {
    console.log("Selected Operator in SingleBookingForm:", selectedOperator);
  }, [selectedOperator]);
  
  
  return (
    <div>
      <h2>Single Booking</h2>
      <Issuance 
        selectedValue={issueAs} 
        onChange={(e) => setIssueAs(e.target.value)} 
        policyType="single" 
        setSelectedCover={() => {}} 
        selectedOperator={selectedOperator} // Pass selectedOperator
        setSelectedOperator={setSelectedOperator} // Capture selected operator
      />
      
      {(issueAs === "User" || (issueAs === "Operator" && selectedOperator)) && (
        <>
        <GetYourQuoteBooking onUrlReady={handleUrlReady} selectedOperator={selectedOperator} />
          {quoteData && (
            <Paper p={"xl"} my={"lg"}>
              {filteredCovers.length > 0 ? (
                <>
                  <Radio.Group
                    value={coverOption}
                    onChange={(value) => {
                      setCoverOption(value);
                      // setShowTravelerForm(false);
                      setShowQuoteLink(false);
                    }}
                    name="coverSelection"
                    label="Select your cover"
                    withAsterisk
                  >
                    <Group mt={"xl"}>
                      {filteredCovers.map((cover, index) => (
                        <Radio key={index} value={cover.type} label={<PriceCard cover={cover} currency={cover.currency} index={index} />} />
                      ))}
                    </Group>
                  </Radio.Group>

                  {/* <BookingForm 
                    hideFields={["csv_file", "plan_name", "client_or_operator", "submit"]}
                    onFormChange={(data) => {
                      setBookingFormData((prev) => ({ ...prev, ...data }));
                    }}
                  /> */}
                  {/* Checkbox for payment */}
                  {/* <div style={{ marginTop: "1rem" }}> 
                    <Checkbox 
                      label="Make payment or not" 
                      checked={makePayment} 
                      onChange={(event) => setMakePayment(event.currentTarget.checked)}
                    />
                  </div> */}
                  
                  {coverOption && (
                    <Center mt={"lg"}>
                      <Group mt="xl">
                        <Button variant="outline" onClick={() => setShowQuoteLink(true)}>Generate Link</Button>
                        {/* or
                        <Button variant="" onClick={handleContinue}>Continue</Button> */}
                      </Group>
                    </Center>
                  )}
                </>
              ) : (
                <p>No covers available.</p>
              )}
            </Paper>
          )}

          {/* {quoteData && coverOption && showTravelerForm && (
            <TravelerInfo 
              data={quoteData} 
              coverType={coverOption} 
              isSubmitting={isSubmitting} 
              handleTraveler={handleTraveler} 
             />
          )} */}

          {/* {quoteData && coverOption && showQuoteLink && (
            <QuoteLink quoteData={quoteData} coverOption={coverOption} bookingFormData={bookingFormData} 
            />
          )} */}
          {quoteData && coverOption && showQuoteLink && (
            <>
              <QuoteLink 
                quoteData={quoteData} 
                coverOption={coverOption} 
                // bookingFormData={bookingFormData} 
              />
            </>
          )}

        </>
      )}
    </div>
  );
};

export default SingleIssuance;
