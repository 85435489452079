import { useState, useCallback } from "react";
import { FileButton, Button, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";
import { filterOptions, sortOptions } from "../../utils/adventures";
import useRbac from "../../../../common/hooks/useRbac/useRbac"; 

const AdventuresPage = () => {
  const endpoint = "cover-api/adventures/";
  const uploadEndpoint = "cover-api/upload-adventures/";

  const { checkPermission } = useRbac(); // Use RBAC hook

  const [adventureCsv, setAdventureCsv] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const handleEdit = (row) => `edit/${row.id}`;
  const handleView = (row) => `view/${row.id}`;

  const handleFileInput = async (file) => {
    if (!checkPermission("create")) return; // Prevent unauthorized uploads
    setAdventureCsv(file);
    open();
  };

  const handleModalClose = () => {
    close();
    setAdventureCsv(null);
  };

  const handleImport = async () => {
    if (!adventureCsv || !checkPermission("create")) return;

    const formData = new FormData();
    formData.append("file", adventureCsv);

    try {
      await fetcher(uploadEndpoint, "POST", formData, {
        "content-type": "multipart/form-data",
      });

      successNotifications("Import successfully");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    } finally {
      setAdventureCsv(null);
    }
  };

  const handleDelete = useCallback(async (row) => {
    if (!checkPermission("delete")) return; // Prevent unauthorized deletion

    const { id: adventure_id, name } = row;

    try {
      await fetcher(`${endpoint}${adventure_id}`, "DELETE");
      successNotifications(`${name} adventure deleted successfully`);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, [checkPermission]);

  const config = {
    fields: [
      { key: "name", label: "Name" },
      { key: "category_name", label: "Category" },
      { key: "asc_level", label: "ASC Level" },
      { key: "created_by_name", label: "Created by" },
      { key: "create_date", label: "Date", type: "datetime" },
    ],
    actions: [
      { label: "View", to: (row) => handleView(row) },
      ...(checkPermission("update") ? [{ label: "Edit", to: (row) => handleEdit(row) }] : []),
    ],
    handler: checkPermission("delete")
      ? [
          {
            label: "Delete",
            handler: (row) => handleDelete(row),
          },
        ]
      : [],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        {checkPermission("create") && (
          <Link to={`add`}>
            <Button variant="outline">+ Add Adventure</Button>
          </Link>
        )}
        {checkPermission("create") && (
          <FileButton onChange={handleFileInput} accept=".csv">
            {(props) => (
              <Button variant="outline2" {...props}>
                + Upload Adventure
              </Button>
            )}
          </FileButton>
        )}
      </Flex>

      {checkPermission("view") && (
        <DataWrapper
          filterOptions={filterOptions}
          sortOptions={sortOptions}
          endpoint={endpoint}
          config={config}
        />
      )}

      <ConfirmModal
        opened={opened}
        onClose={handleModalClose}
        onConfirm={handleImport}
        title="Traveller Policy"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        Are you sure you want to upload adventure CSV?{" "}
        {adventureCsv && (
          <Text size="sm" ta="center" mt="sm">
            Picked File: {adventureCsv.name}
          </Text>
        )}
      </ConfirmModal>
    </>
  );
};

export default AdventuresPage;
