import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs } from "@mantine/core";
import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";

const UserIssuedPolicies = () => {
  const outletContext = useOutletContext();
  const user = outletContext ? outletContext.user : null;
  console.log(user,'useruseruseruser');
  const { user_type } = useSelector((state) => state.auth);
  console.log(user_type,'user_type');
  const isSuperUser = user_type[0].includes("is_superuser");
  console.log(isSuperUser,'isSuperUser')

  const tripsEndpoint = isSuperUser
    ? `issuance/policies/user-specific/trips?staff_id=${user.user_profile_info.user.id}`
    : `issuance/policies/user-specific/trips`;

  const travellersEndpoint = isSuperUser
    ? `issuance/policies/user-specific/travellers/?staff_id=${user.user_profile_info.user.id}`
    : `issuance/policies/user-specific/travellers/`;

  const handleTripDetails = (row) => {
    return `${row.id}`;
  };

  const handleTravellerDetails = (row) => {
    return `${row.id}`; 
  };
  

  const tripsConfig = {
    fields: [
      { key: "cover_type", label: "Cover Type" },
      { key: "traveller_from_name", label: "From" },
      { key: "traveller_to_name", label: "To" },
      { key: "no_of_pax", label: "Pax" },
      { key: "coverage_start_date", label: "Start Date" },
      { key: "coverage_end_date", label: "End Date" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTripDetails(row),
        isButton: true,
      },
    ],
  };

  const travellersConfig = {
    fields: [
      { key: "first_name", label: "Name" },
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "nationality_name", label: "Nationality" },
      { key: "email", label: "Email" },
      { key: "dob", label: "DOB" },
    ],
    actions: [
    {
      label: "Details",
      to: (row) => handleTravellerDetails(row),
      isButton: true,
    },
  ],
  };

  return (
    <>
      <Tabs defaultValue="trips" variant="pills">
        <Tabs.List>
          <Tabs.Tab value="trips">Trips</Tabs.Tab>
          <Tabs.Tab value="travellers">Travellers</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="trips" mt={"md"}>
          <DataWrapper endpoint={tripsEndpoint} config={tripsConfig} />
        </Tabs.Panel>

        <Tabs.Panel value="travellers" mt={"md"}>
          <DataWrapper
            endpoint={travellersEndpoint}
            config={travellersConfig}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default UserIssuedPolicies;
