import Issuance from "../pages/Issuance/Issuance";
import TravelerInfo from "../containers/SingleIssuance/TravelerInfo";
import Policies from "../pages/Policies/Policies";
import TripDetails from "../containers/TripTravellerDetail/TripDetails";
import TravellerDetails from "../containers/TripTravellerDetail/TravellerDetails";
import UnissuedPolicies from "../pages/UnissuedPolicies/UnissuedPolicies";
import QuoteLinks from "../pages/QuoteLinks/QuoteLinks";
import Endorsement from "../containers/Endorsement/Endorsement";
import SingleIssuance from "../containers/SingleIssuance/SingleIssuance";
import BulkIssuance from "../containers/BulkIssuane/BulkIssuance";

const IssuanceRoutes = [
  {
    path: "issuance",
    children: [
      { path: ":policy_type", element: <Issuance /> },
      { path: ":policy_type/traveller", element: <TravelerInfo /> },
      { path: "user-specific-policy", element: <Policies /> },
      { path: "policies/trip/:id", element: <TripDetails /> },
      { path: "policies/travellers/:id", element: <TravellerDetails /> },
      { path: "unissued-policies", element: <UnissuedPolicies /> },
      { path: "quote-links", element: <QuoteLinks /> },
      { path: "endorsement/:id", element: <Endorsement /> },
      { path: "single", element: <SingleIssuance /> },
      { path: "bulk", element: <BulkIssuance /> },
    ],
  },
];

export default IssuanceRoutes;
