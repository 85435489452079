import useAuthContext from "../../context/useAuthContext/useAuthContext";

const useRbac = () => {
  const { allowedActions } = useAuthContext();

  const checkPermission = (requiredAction) => {
    return allowedActions.some(
      (action) =>
        action.roleIsActive &&
        action.action.toLowerCase().includes(requiredAction.toLowerCase())
    );
  };

  const filterActionsByModule = (moduleUrl) => {
    return allowedActions.filter(
      (action) => action.moduleUrl === moduleUrl && action.roleIsActive
    );
  };

  return {
    checkPermission,
    filterActionsByModule,
  };
};

export default useRbac;
