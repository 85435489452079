import VendorsPage from "../pages/VendorsPage";
import AddVendor from "../container/vendorList/AddVendor/AddVendor";
import EditVendor from "../container/vendorList/EditVendor/EditVendor";
import ViewVendor from "../container/vendorList/ViewVendor/ViewVendor";

const VendorRoutes = [
  { path: "vendor", element: <VendorsPage /> },
  { path: "vendor/add", element: <AddVendor /> },
  { path: "vendor/edit/:id", element: <EditVendor /> },
  { path: "vendor/view/:id", element: <ViewVendor /> },
];

export default VendorRoutes;
