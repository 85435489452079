import useSWR from "swr";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetcher } from "../../../../services/fetcher";
import { Paper, Select } from "@mantine/core";

const baseUrl = 'issuance/user-cover-plan-access';

const Issuance = ({ selectedValue, policyType, onChange, selectedCover, setSelectedCover, selectedOperator, setSelectedOperator }) => {
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.user?.role;
  const role = useSelector((state) => state.auth.role);
  
  // console.log(role,'rolerolerolerole');
  // console.log(userRole,'userRoleuserRole');

  // const [selectedOperator, setSelectedOperator] = useState(null);
  const [operatorCovers, setOperatorCovers] = useState([]);

  const getApiUrl = () => {
    if (role.includes("Operator")) {
      return `${baseUrl}/?policy_type=bulk`;
    }

    if (!userRole) return null;
    
    if (selectedValue === "User" && ["Issuance Team", "Sales Team", "Super Admin"].includes(userRole)) {
      return `${baseUrl}/?policy_type=${policyType}&issue_as=agent`;
    } else if (selectedValue === "Operator") {
      return `${baseUrl}/?policy_type=${policyType}&issue_as=operator`;
    }
    return null;
  };

  const apiUrl = getApiUrl();
  const { data, error, isLoading } = useSWR(
    apiUrl ? [apiUrl, "GET"] : null,
    ([url, method]) => fetcher(url, method), 
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

 

  const coverOptions = data?.covers?.flatMap((cover) =>
    

    cover.customize_covers?.map((item) => ({
      value: item.id.toString(),
      label: `${item.title} - ${item.price} ${item.currency}`,
    })) || []

  ) || [];
 


  const operatorOptions =
    selectedValue === "Operator" && Array.isArray(data?.choose_operator)
      ? data.choose_operator.map((operator) => ({
          value: operator.id.toString(),
          label: operator.name,
        }))
      : [];



  const handleOperatorChange = async (value) => {
  

    setSelectedOperator(value);
    setSelectedCover(null);
    setOperatorCovers([]);


    if (value) {
      const operatorId = value;
      const apiUrl = `${baseUrl}/?policy_type=${policyType}&issue_as=operator&operator=${operatorId}`;
      try {
        const response = await fetcher(apiUrl, "GET");
        const covers = response?.covers?.flatMap((cover) =>
          cover.customize_covers?.map((item) => ({
            value: item.id.toString(),
            label: `${item.title} - ${item.price} ${item.currency}`,
          })) || []
        ) || [];
        setOperatorCovers(covers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    // console.log("Selected Operator Updated:", selectedOperator);
  }, [selectedOperator]);
  
  return (
    <>
      {!role.includes("Operator") && (
        <Paper shadow="md" p="lg">
          <label>
            <input type="radio" value="User" checked={selectedValue === "User"} onChange={onChange} />
            Issue As: User
          </label>
          <label>
            <input type="radio" value="Operator" checked={selectedValue === "Operator"} onChange={onChange} />
            Issue As: Operator
          </label>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error fetching data</p>}
        </Paper>
      )}
      
      {policyType === "bulk" && (role.includes("Operator") || selectedValue === "User") && coverOptions.length > 0 && (
        <Paper shadow="md" p="lg" mt="lg" mb="lg">
          <Select
            label="Choose Cover"
            placeholder="Select Cover Plan"
            data={coverOptions}
            value={selectedCover?.value || ""}
            onChange={(value) => {
                const selected = coverOptions.find((option) => option.value === value);
                setSelectedCover(selected || null); 
            }}
            clearable
          />
        </Paper>
      )}

      
      {/* {policyType === "bulk" && selectedValue === "User" && coverOptions.length > 0 && (
        <Paper shadow="md" p="lg" mt="lg" mb="lg">
          <Select
            label="Choose Cover"
            placeholder="Select Cover Plan"
            data={coverOptions}
            value={selectedCover?.value || ""}
            onChange={(value) => {
                const selected = coverOptions.find((option) => option.value === value);
                setSelectedCover(selected || null); 
            }}
            clearable
          />
        </Paper>
      )} */}

      {selectedValue === "Operator" && operatorOptions.length > 0 && (
        <Paper shadow="md" p="lg" mt="lg" mb="lg">
          <Select
            label="Select Operator"
            placeholder="Choose an operator"
            data={operatorOptions}
            value={selectedOperator}
            onChange={handleOperatorChange}
            clearable
          />
        </Paper>
      )}

      {policyType === "bulk" && selectedValue === "Operator" && operatorCovers.length > 0 && (
        <Paper shadow="md" p="lg" mt="lg" mb="lg">
          <Select
            label="Choose Cover"
            placeholder="Select Cover Plan"
            data={operatorCovers}
            onChange={(value) => {
              const selected = operatorCovers.find((option) => option.value === value);
              setSelectedCover(selected || null); 
            }}
            clearable
          />
        </Paper>
      )}
    </>
  );
};

export default Issuance;
