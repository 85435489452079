import VendorRoutes from "../features/vendors/routes/VendorListRoutes";
import UserRoutes from "../features/users/routes/UserRoutes";
import PaymentRoutes from "../features/payments/routes/PaymentRoutes";
import OperatorRoutes from "../features/operators/routes/OperatorRoutes";
import IssuanceRoutes from "../features/issuance/routes/IssuanceRoutes";
import MasterWalletRoutes from "../features/master-wallet/routes/MasterWalletRoutes";
import CoverRoutes from "../features/covers/routes/CoverRoutes";
import PoliciesRoutes from "../features/policies/routes/PoliciesRoutes";
import BookingRoutes from "../features/booking/routes/BookingRoutes";
import WebsiteRoutes from "../features/website/routes/WebsiteRoutes";
import BulkMailRoutes from "../features/bulk-email/routes/BulkMailRoutes";
import UserWalletRoutes from "../features/user-wallet/routes/UserWallet";

const appRoutes = [
  ...VendorRoutes,
  ...UserRoutes,
  ...PaymentRoutes,
  ...OperatorRoutes,
  ...IssuanceRoutes,
  ...MasterWalletRoutes,
  ...CoverRoutes,
  ...PoliciesRoutes,
  ...BookingRoutes,
  ...WebsiteRoutes,
  ...BulkMailRoutes,
  ...UserWalletRoutes
];

export default appRoutes;
