import { useState } from "react";
import useSWR from "swr";
import AuthLayout from "../../../../common/templates/AuthLayout/AuthLayout";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setAuth, setPermission } from "../../authSlice";
import AuthAPI from "../../endpoints";
import { fetcher } from "../../../../services/fetcher";
import MultiLoginForm from "../../components/MultiLoginForm/MultiLoginForm";
import { errorNotifications } from "../../../../utils/handleNotifications";
import { USER_TYPES } from "../../../../utils/userTypes";
import { setUserProfile } from "../../userProfileSlice";
import { setOperatorProfile } from "../../operatorProfileSlice";
import { rolePermissions, userProfile, operatorProfile } from "../../../../api/endpoints";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userType, setUserType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const from = location.state?.from?.pathname || "/dashboard";

  const endpoint = userType === USER_TYPES.OPERATOR ? operatorProfile :userProfile;

  // console.log("User profile API endpoint:", endpoint);
  // Fetch user profile after login
  const { mutate } = useSWR(userType ? endpoint : null, fetcher, {
    onSuccess: (data) => {
      // console.log("User profile:", data); 
      userType === USER_TYPES.OPERATOR ?  (      
        dispatch(setOperatorProfile(data))):
        (
        dispatch(setUserProfile(data))
        )
      // console.log("User profile saved to Redux.");
      navigate(from, { replace: true });
    },
    onError: (error) => {
      console.error("Error fetching user profile:", error);
    },
  });


  // Fetch user permissions after login
  const { mutate: mutateUserPermission } = useSWR(
    userType ? rolePermissions : null,
    fetcher,
    {
      onSuccess: (data) => {
        // console.log("User permissions data fetched successfully:", data);
        dispatch(setPermission(data.data.user.userModulePages));
        // console.log("Permissions saved to Redux.");
      },
      onError: (error) => {
        console.error("Error fetching user permissions:", error);
      },
    }
  );

  // Handles sending OTP
  const handleSendOtp = (userID) => {
    // console.log("Sending OTP for user ID:", userID);
    return new Promise((resolve, reject) => {
      AuthAPI.loginOTP(userID)
        .then(() => {
          // console.log("OTP sent successfully.");
          resolve();
        })
        .catch((error) => {
          // console.error("Error sending OTP:", error);
          errorNotifications(error.response.data, error);
          reject(error);
        });
    });
  };

  // Handles user authentication (login)
  const handleAuthentication = (authMethod) => async (loginData) => {
    setIsSubmitting(true);
    // console.log("Logging in with data:", loginData);
    try {
      const response = await authMethod(loginData);
      // console.log("Login successful. Response data:", response.data);

      dispatch(setAuth(response.data));
      // console.log("Auth state updated in Redux.");

      setUserType(response.data.user_type[0]);
      // console.log("User type set:", response.data.user_type[0]);

      await mutate();
      await mutateUserPermission();
    } catch (error) {
      // console.error("Login failed:", error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
      // console.log("Login process completed.");
    }
  };

  return (
    <AuthLayout title={"ASC360 Portal"}>
      <MultiLoginForm
        onLoginPassword={handleAuthentication(AuthAPI.login)}
        onLoginOtp={handleAuthentication(AuthAPI.verifyOTP)}
        onSendOtp={handleSendOtp}
        isSubmitting={isSubmitting}
      />
    </AuthLayout>
  );
}

export default LoginPage;
