import { useState, useCallback, useMemo, startTransition } from "react";
import { FloatingIndicator, Tabs } from "@mantine/core";
import { useSelector } from "react-redux";
import useSWR from "swr";

import { fetcher } from "../../../../services/fetcher";
import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";

import {
  filterOptions as baseFilterOptions,
  sortOptions,
} from "../../utils/trips";

import {
  filterOptions as travellersFilterOptions,
  sortOptions as travellersSortOptions,
} from "../../utils/travellers";

import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";

import { USER_TYPES } from "../../../../utils/userTypes";

import classes from "./Policies.module.css";

const trips = "issuance/policies/user-specific/trips/";
const travellers = "issuance/policies/user-specific/travellers/";
const operators = "operator/operator-profiles/";

const Policies = () => {
  const [rootRef, setRootRef] = useState(null);
  const [value, setValue] = useState("1");
  const [controlsRefs, setControlsRefs] = useState({});
  const [currentSelection, setCurrentSelection] = useState([]);

  const { user_type } = useSelector((state) => state.auth);
  const userProfile = useSelector((state) => state.userProfile);
  const operatorProfile = useSelector((state) => state.operatorProfile);

  const tripsEndpoint = useMemo(() => {
    if (user_type === USER_TYPES.OPERATOR) {
      return `${trips}?operator_id=${operatorProfile?.operator.id}`;
    } else {
      return `${trips}?staff_id=${userProfile?.user.id}`;
    }
  }, [user_type, operatorProfile, userProfile]);

  const travellersEndpoint = useMemo(() => {
    if (user_type === USER_TYPES.OPERATOR) {
      return `${travellers}?operator_id=${operatorProfile?.operator.id}`;
    } else {
      return `${travellers}?staff_id=${userProfile?.user.id}`;
    }
  }, [user_type, operatorProfile, userProfile]);

  const { data: operatorData, isLoading: isOperatorsLoading } = useSWR(
    operators,
    fetcher
  );

  const setControlRef = (val) => (node) => {
    controlsRefs[val] = node;
    setControlsRefs(controlsRefs);
  };

  const handleTabChange = (newValue) => {
    startTransition(() => {
      setValue(newValue);
    });
  };

  const operatorOptions = useMemo(() => {
    if (!operatorData) return;

    if (operatorData) {
      return operatorData.map((operator) => ({
        name: operator.operator_name,
        id: operator.operator.id,
      }));
    }
  }, [operatorData]);

  const handleTripDetails = (row) => {
    return `/issuance/policies/trip/${row.id}`;
  };

  const handleTravellerDetails = (row) => {
    return `/issuance/policies/travellers/${row.id}`;
  };

  const handleEndorsementDetails = (row) => {
    return `/issuance/endorsement/${row.id}`;
  };

  const handleResendDoc = useCallback(async (row) => {
    const { id: traveller_id } = row;

    const payload = {
      traveller_id: traveller_id,
    };

    try {
      await fetcher("issuance/generate-or-send-document/", "POST", payload);
      successNotifications("Success! Document resent");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data.error, error);
    }
  }, []);

  const handleDownloadDoc = useCallback(async (row) => {
    try {
      const { cover_document: downloadUrl } = row;

      if (
        !downloadUrl ||
        !downloadUrl.startsWith("https://asc360.s3.amazonaws.com/")
      ) {
        successNotifications("Download URL is not available.");
        return;
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "CoverDoc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      successNotifications("Error downloading document:", error);
    }
  }, []);

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleTravellersExport = async () => {
    if (currentSelection.length === 0) {
      return successNotifications("Please select travellers");
    }

    const payload = { traveller_ids: currentSelection };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_travellers.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Travellers exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleCoversExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    try {
      const response = await fetcher(
        `issuance/download-cover-documents/?trip_id=${trip_id}`,
        "POST",
        null,
        {},
        { responseType: "arraybuffer" }
      );

      if (response) {
        const zipBlob = new Blob([response], {
          type: "application/zip",
        });

        const url = window.URL.createObjectURL(zipBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `ASCTrip${trip_id}.zip`;

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Covers of trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const handleTripsExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    const payload = { trip_id: trip_id };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_trips.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const tripConfig = {
    fields: [
      { key: "id", label: "Booking ID" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "traveller_from_name", label: "Travel From" },
      { key: "traveller_to_name", label: "Travel To" },
      { key: "no_of_pax", label: "Pax" },
      { key: "tour_operator_name", label: "Tour Operator" },
      {
        key1: "currency",
        key2: "total_amount",
        label: "Total Amount",
        type: "combine",
      },
      { key: "coverage_start_date", label: "Start Date", type: "date" },
      { key: "coverage_end_date", label: "End Date", type: "date" },
      { key: "created_on", label: "Booking Date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "status", label: "Status", type: "status" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTripDetails(row),
        isButton: true,
      },
    ],
    handler: [
      {
        label: "Export",
        handler: (row) => handleTripsExport(row),
      },
      {
        label: "Export Cover",
        handler: (row) => handleCoversExport(row),
      },
    ],
  };

  const travellerConfig = {
    fields: [
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "email", label: "Traveller Email" },
      { key: "first_name", label: "Traveller Name" },
      { key: "nationality_name", label: "Nationality" },
      { key: "dob", label: "Date of birth" },
      { key: "cover_issued_dt", label: "Issued date", type: "datetime" },
      { key: "created_on", label: "Booking date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "policy_status_traveller_wise", label: "Status", type: "status" },
    ],
    handler: [
      {
        label: "Resend",
        handler: (row) => handleResendDoc(row),
      },
      {
        label: "Cover Doc",
        handler: (row) => handleDownloadDoc(row),
      },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTravellerDetails(row),
        isButton: true,
      },
      {
        label: "Endorsement",
        to: (row) => handleEndorsementDetails(row),
      },
    ],
    selectionCheckbox: true,
    selectionActions: [{ label: "Export", handler: handleTravellersExport }],
  };

  const filterOptions = useMemo(
    () => [
      ...baseFilterOptions,
      {
        label: "Operator",
        value: "operator_id",
        type: "select",
        options: operatorOptions,
        roles: ["is_superuser"],
      },
    ],
    [operatorOptions]
  );

  return (
    <Tabs variant="none" value={value} onChange={handleTabChange}>
      <Tabs.List ref={setRootRef} className={classes.list}>
        <Tabs.Tab value="1" ref={setControlRef("1")} className={classes.tab}>
          Booking
        </Tabs.Tab>

        <Tabs.Tab value="2" ref={setControlRef("2")} className={classes.tab}>
          Travellers
        </Tabs.Tab>

        <FloatingIndicator
          target={value ? controlsRefs[value] : null}
          parent={rootRef}
          className={classes.indicator}
        />
      </Tabs.List>

      <Tabs.Panel value={"1"}>
        <DataWrapper
          filterOptions={isOperatorsLoading ? [] : filterOptions}
          endpoint={tripsEndpoint}
          config={tripConfig}
          sortOptions={sortOptions}
        />
      </Tabs.Panel>

      <Tabs.Panel value={"2"}>
        <DataWrapper
          filterOptions={travellersFilterOptions}
          sortOptions={travellersSortOptions}
          endpoint={travellersEndpoint}
          config={travellerConfig}
          onSelectHandler={handleSelection}
        />
      </Tabs.Panel>
    </Tabs>
  );
};

export default Policies;
