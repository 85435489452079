// import Operators from "../pages/Operators/Operators";
// import AddOperator from "../containers/AddOperator/AddOperator";
// import OperatorLayout from "../layout/OperatorLayout";
// import OperatorProfile from "../containers/OperatorProfile/OperatorProfile";
// import OperatorWallet from "../containers/Wallet/OperatorWallet";
// import AssignCoverOperator from "../containers/AssignCoverOperator/AssignCoverOperator";
// import Commission from "../containers/Commission/Commission";
// import OperatorPayments from "../containers/Payments/Payments";
// import OperatorIssuedPolicies from "../containers/PoliciesIssued/PoliciesIssued";
// import TripDetails from "../../issuance/containers/TripTravellerDetail/TripDetails";

// // Retrieve user role from localStorage
// const authData = localStorage.getItem("persist:auth");

// let isSuperuser = false; // Default to non-superuser

// if (authData) {
//   try {
//     const parsedAuth = JSON.parse(authData); // Parse outer JSON
//     const userProfile = JSON.parse(parsedAuth.userProfile); // Parse "userProfile" field

//     // console.log("Parsed User Profile:", userProfile);

//     // Check if role exists inside userProfile.user and matches "Super Admin"
//     if (userProfile.user && userProfile.user.role === "Super Admin") {
//       isSuperuser = true;
//     }
//   } catch (error) {
//     console.error("Error parsing auth data:", error);
//   }
// }

// // console.log("isSuperuser:", isSuperuser);

// // Common Routes for both superuser & non-superuser
// const commonRoutes = [
//   { path: "operator-profile", element: <OperatorProfile /> },
//   { path: "wallet", element: <OperatorWallet /> },
//   { path: "operator-assign-covers", element: <AssignCoverOperator /> },
//   { path: "commission", element: <Commission /> },
//   { path: "operator-payments", element: <OperatorPayments /> },
//   { path: "policies-issued", element: <OperatorIssuedPolicies /> },
//   { path: "policies-issued/:id", element: <TripDetails /> },
// ];

// // Helper function to generate routes based on user role
// const withLayout = (children) => {
//   if (isSuperuser) {
//     return [{ path: "operator/operators/:uuid", element: <OperatorLayout />, children }];
//   } else {
//     return children.map((route) => ({
//       ...route,
//       path: `operator/operators/${route.path}`,
//     }));
//   }
// };

// // Main operator routes
// const OperatorRoutes = [
//   { path: "operator/operators/", element: <Operators /> },
//   { path: "operator/operators/create", element: <AddOperator /> },
//   ...withLayout(commonRoutes),
// ];

// export default OperatorRoutes;

import Operators from "../pages/Operators/Operators";
import AddOperator from "../containers/AddOperator/AddOperator";
import OperatorLayout from "../layout/OperatorLayout";
import OperatorProfile from "../containers/OperatorProfile/OperatorProfile";
import OperatorWallet from "../containers/Wallet/OperatorWallet";
import AssignCoverOperator from "../containers/AssignCoverOperator/AssignCoverOperator";
import Commission from "../containers/Commission/Commission";
import OperatorPayments from "../containers/Payments/Payments";
import OperatorIssuedPolicies from "../containers/PoliciesIssued/PoliciesIssued";
import TripDetails from "../../issuance/containers/TripTravellerDetail/TripDetails";

// Retrieve user role from localStorage
const authData = localStorage.getItem("persist:auth");

let isSuperuserOrSales = false; // Default to non-superuser

if (authData) {
  try {
    const parsedAuth = JSON.parse(authData); // Parse outer JSON
    const userProfile = JSON.parse(parsedAuth.userProfile); // Parse "userProfile" field
    // console.log(userProfile,'userProfile');

    // Check if  role exists inside userProfile.user and matches "Super Admin" or "Sales Team"
    if (userProfile.user && (userProfile.user.role === "Super Admin" || userProfile.user.role === "Sales Team")) {
      isSuperuserOrSales = true;
    }
  } catch (error) {
    console.error("Error parsing auth data:", error);
  }
}

// Common Routes for both roles
const commonRoutes = [
  { path: "operator-profile", element: <OperatorProfile /> },
  { path: "wallet", element: <OperatorWallet /> },
  { path: "operator-assign-covers", element: <AssignCoverOperator /> },
  { path: "commission", element: <Commission /> },
  { path: "operator-payments", element: <OperatorPayments /> },
  { path: "policies-issued", element: <OperatorIssuedPolicies /> },
  { path: "policies-issued/:id", element: <TripDetails /> },
];

// Helper function to generate routes based on user role
const withLayout = (children) => {
  if (isSuperuserOrSales) {
    return [{ path: "operator/operators/:uuid", element: <OperatorLayout />, children }];
  } else {
    return children.map((route) => ({
      ...route,
      path: `operator/operators/${route.path}`,
    }));
  }
};

// Main operator routes
const OperatorRoutes = [
  { path: "operator/operators/", element: <Operators /> },
  { path: "operator/operators/create", element: <AddOperator /> },
  ...withLayout(commonRoutes),
];

export default OperatorRoutes;
