import { useState } from "react";
import {
  Stepper,
  Button,
  TextInput,
  PasswordInput,
  PinInput,
  Divider,
  Center,
  Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { EMAIL_REGEX } from "../../../../utils/regex";
import { successNotifications } from "../../../../utils/handleNotifications";
import classes from "./MultiLoginForm.module.css";

function MultiLoginForm({
  onLoginPassword,
  isSubmitting,
  onSendOtp,
  onLoginOtp,
}) {
  const [active, setActive] = useState(0);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: (values) => {
      if (active === 0) {
        return {
          email: EMAIL_REGEX.test(values.email) ? null : "Enter valid email",
        };
      }

      return {};
    },
  });

  const formOtp = useForm({
    initialValues: {
      otp: "",
    },

    validate: (values) => {
      if (active === 3) {
        return {
          otp:
            values.otp.trim().length < 6
              ? "Name must include at least 6 characters"
              : null,
        };
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const handleEmailKeyPress = (event) => {
    if (event.key === "Enter" && !form.validateField("email").hasError) {
      nextStep();
    }
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === "Enter" && form.values.password.trim() !== "") {
      onLoginPassword(form.values);
    }
  };

  return (
    <>
      <Stepper
        active={active}
        styles={{
          step: {
            display: "none",
          },
          separator: {
            display: "none",
          },
        }}
      >
        <Stepper.Step>
          <TextInput
            placeholder={"Enter your email address"}
            label={"Email"}
            type={"email"}
            size={"md"}
            variant={"filled"}
            {...form.getInputProps("email")}
            onKeyDown={handleEmailKeyPress}
          />

          {active !== 3 && (
            <Button variant="gradient" fullWidth my={"xl"} onClick={nextStep}>
              Sign In
            </Button>
          )}
        </Stepper.Step>

        <Stepper.Step>
          <p className={classes.mail}>Email</p>
          {active !== 0 && (
            <Button
              fullWidth
              style={{ backgroundColor: "#fff", color: "#777", display: "flex",           
                justifyContent: "flex-start"
                }}
              onClick={prevStep}
            >
              {form.values.email}
            </Button>
          )}

          <PasswordInput
            mt="md"
            label="Password"
            placeholder="Password"
            {...form.getInputProps("password")}
            onKeyDown={handlePasswordKeyPress}
          />

          <Button
            fullWidth
            onClick={form.onSubmit(onLoginPassword)}
            variant="gradient"
            mt="md"
            loading={isSubmitting}
            loaderProps={{ type: "dots" }}
          >
            Sign In
          </Button>

          <Divider label="Or login with OTP" labelPosition="center" my="xl" />

          {active !== 3 && (
            <Button
              variant="outline"
              fullWidth
              onClick={() => {
                onSendOtp({ email_or_phone: form.values?.email }).then(() => {
                  nextStep();
                });
              }}
            >
              Login with OTP
            </Button>
          )}
        </Stepper.Step>

        <Stepper.Step>
          <p className={classes.mail}>Email</p>
          {active !== 0 && (
            <Button
              fullWidth
              style={{ backgroundColor: "#fff", color: "#777" }}
              onClick={prevStep}
            >
              {form.values.email}
            </Button>
          )}

          <Center mt={"xl"}>
            <PinInput
              label="Enter OTP"
              placeholder=""
              type="number"
              length={6}
              size="md"
              {...formOtp.getInputProps("otp")}
            />
          </Center>

          <Group mt={"xl"} justify="space-between">
            <Button
              variant="subtle"
              onClick={() => {
                onSendOtp({ email_or_phone: form.values?.email }).then(() => {
                  successNotifications("OTP has been resent");
                });
              }}
              className={classes.control}
            >
              Resend OTP
            </Button>

            <Button
              variant="gradient"
              className={classes.control}
              onClick={formOtp.onSubmit(onLoginOtp)}
            >
              Submit OTP
            </Button>
          </Group>
        </Stepper.Step>
      </Stepper>
    </>
  );
}

export default MultiLoginForm;
