import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  role: null,
  permission: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      // console.log(state, "state");
      // console.log(action, "action");
      // console.log(action.payload.role, 'checking');
      // console.log(state.role,"role");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.email || state.user,
        accessToken: action.payload.access || state.accessToken,
        refreshToken: action.payload.refresh || state.refreshToken,
        permission: action.payload.permissions,
        role: action.payload.role,
        user_type: action.payload.user_type || state.user_type,
      };
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = null;
      state.permission = null;
    },
  },
});

export const { setAuth, setPermission, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
