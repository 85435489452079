import axios from "axios";
import store from "../store";
import apiTokenRefresh from "./refreshToken";

const BASE_URL = `${import.meta.env.VITE_API_ENDPOINT}${
  import.meta.env.VITE_API_VERSION
}`;

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  // headers: { "Content-Type": "application/json" },
});

// Request interceptor
axiosPrivate.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().auth;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const { refreshToken } = store.getState().auth;
      const newAccessToken = await apiTokenRefresh(refreshToken);
      prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      return axiosPrivate(prevRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
