import Payments from "../pages/Payments";

const PaymentRoutes = [
  {
    path: "asc-wallet",
    children: [
        { path: "payments", element: <Payments /> },
    ],
  },
];

export default PaymentRoutes;
