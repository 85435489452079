import { useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Text } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import MainLoader from "../../../../common/components/Loader/Loader";
import ViewEditTraveller from "../../forms/ViewEditTraveller/ViewEditTraveller";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";

const TravellerDetails = () => {
  const { id } = useParams();
  const endpoint = `issuance/policies/traveller/${id}/`;
  const { data, isLoading, error, mutate } = useSWR(endpoint, fetcher);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = async (updatedData) => {
    if (Object.keys(updatedData).length === 0) {
      setIsEditing(false);
      return;
    }

    try {
      await fetcher(endpoint, "PUT", updatedData);
      await mutate();
      setIsEditing(false);
      successNotifications("Traveller detail has been updated");
    } catch (error) {
      console.error("Error updating traveller:", error);
      errorNotifications(error);
    }
  };
  

  if (isLoading) return <MainLoader />;
  if (error) return <Text c="red">Error loading traveller details</Text>;

  console.log(data.id,"data");
  console.log(endpoint, "endpoint");

  return (
    <ViewEditTraveller
      data={data ? data : {}}
      onSubmit={handleSubmit}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  );
};

export default TravellerDetails;
