import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { 
  Select, SimpleGrid, Group, Button, Textarea, 
  FileInput, Text, TextInput 
} from "@mantine/core";
import {
  BookingPriority,
  PaymentStatus,
  BookingReceived,
} from "../../utils/bookingOptions";

const userendpoint = "auth-api/user-create/";

const BookingForm = ({ 
  onSubmit, 
  onFormChange = () => {}, // Default function for safety
  initialData = {}, 
  selectedCover, 
  hideFields = [] 
}) => {
  const userProfile = useSelector((state) => state.userProfile?.user);
  const { data: users } = useSWR(userendpoint, fetcher);
  const salesAgentId = userProfile?.id || "";

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch, // 👀 Watch for form data changes
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialData,
      sales_agent: salesAgentId,
      plan_name: selectedCover?.label || "",
    },
  });

  const formData = watch(); // Get current form data

  // 🔹 Effect to update plan_name when selectedCover changes
  useEffect(() => {
    if (selectedCover) {
      setValue("plan_name", selectedCover.label);
    }
  }, [selectedCover, setValue]);

  // 🔹 Effect to update sales_agent when userProfile updates
  useEffect(() => {
    if (salesAgentId) {
      setValue("sales_agent", salesAgentId);
    }
  }, [salesAgentId, setValue]);

  // Effect to notify parent component about form changes
  useEffect(() => {
    // console.log("BookingForm Data Updated:", formData); // Debugging
    onFormChange(formData);
  }, [formData, onFormChange]);

  return (
    <>
      <Text mb="lg" size="lg" mt="lg" fw="700">
        Booking Status
      </Text>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <SimpleGrid cols={2}>
          {/* Sales Agent (Read-Only) */}
          <Controller
            name="sales_agent"
            control={control}
            rules={{ required: "Sales agent is required" }}
            render={() => (
              <TextInput
                label="Sales Agent"
                withAsterisk
                placeholder="Enter Sales Agent Name"
                value={`${userProfile?.first_name || ""} ${userProfile?.last_name || ""}`.trim()}
                readOnly
                error={errors.sales_agent?.message}
              />
            )}
          />

          {/* Plan Name */}
          {!hideFields.includes("plan_name") && (
            <Controller
              name="plan_name"
              control={control}
              rules={{ required: "Plan name is required" }}
              render={({ field }) => (
                <TextInput 
                  label="Plan Name" 
                  withAsterisk
                  placeholder="Enter Plan Name" 
                  {...field} 
                  value={field.value || selectedCover?.label || ""}
                  error={errors.plan_name?.message} 
                />
              )}
            />
          )}

          {/* Client/Operator */}
          {!hideFields.includes("client_or_operator") && (
            <Controller
              name="client_or_operator"
              control={control}
              rules={{ required: "Client/Operator is required" }}
              render={({ field }) => (
                <TextInput 
                  label="Operator Name" 
                  withAsterisk
                  placeholder="Enter Client or Operator name" 
                  {...field} 
                  error={errors.client_or_operator?.message} 
                />
              )}
            />
          )}

          {/* Booking Priority */}
          <Controller
            name="priority"
            control={control}
            rules={{ required: "Priority is required" }}
            render={({ field }) => (
              <Select 
                label="Booking Priority" 
                withAsterisk 
                data={BookingPriority} 
                placeholder="Booking Priority" 
                {...field} 
                error={errors.priority?.message} 
              />
            )}
          />

          {/* Payment Status */}
          <Controller
            name="payment_status"
            control={control}
            rules={{ required: "Payment status is required" }}
            render={({ field }) => (
              <Select 
                label="Payment Status" 
                withAsterisk 
                data={PaymentStatus} 
                placeholder="Payment Status" 
                {...field} 
                error={errors.payment_status?.message} 
              />
            )}
          />

          {/* Booking Received */}
          <Controller
            name="booking_received"
            control={control}
            rules={{ required: "Booking received is required" }}
            render={({ field }) => (
              <Select 
                label="Booking Received" 
                withAsterisk 
                data={BookingReceived} 
                placeholder="Booking Received" 
                {...field} 
                error={errors.booking_received?.message} 
              />
            )}
          />

          {/* CSV File Upload */}
          {!hideFields.includes("csv_file") && (
            <Controller
              name="csv_file"
              control={control}
              // rules={{ required: "CSV file is required" }}
              render={({ field }) => (
                <FileInput
                  name="csv_file"
                  label="CSV File"
                  // withAsterisk 
                  placeholder="Upload CSV"
                  clearable
                  accept=".csv"
                  onChange={(file) => {
                    // console.log("Selected CSV File:", file); // Debugging
                    field.onChange(file);
                  }}
                  error={errors.csv_file?.message} 
                />
              )}
            />
          )}

          {/* Issuance Representative */}
          <Controller
            name="issuance_representative"
            control={control}
            rules={{ required: "Issuance representative is required" }}
            render={({ field }) => (
              <Select
                label="Issuance Representative"
                withAsterisk
                placeholder="Select Issuance Representative"
                data={users
                  ? users.map((user) => ({
                      value: user.id.toString(),
                      label: `${user.user_profile_info.user.first_name} ${user.user_profile_info.user.last_name}`,
                    }))
                  : []
                }
                {...field}
                error={errors.issuance_representative?.message}
              />
            )}
          />

          {/* Payment Receipt Upload */}
          <Controller
            name="payment_receipt"
            control={control}
            render={({ field }) => (
              <FileInput 
                name="payment_receipt"
                label="Payment Receipt" 
                placeholder="Upload Receipt" 
                clearable 
                accept="image/png,image/jpeg,application/pdf"
                onChange={(file) => field.onChange(file)}
                error={errors.payment_receipt?.message} 
              />
            )}
          />

          {/* Remarks */}
          <Textarea label="Remarks" placeholder="Enter remarks" {...register("remarks")} />
        </SimpleGrid>

        {/* Submit Button */}
        {!hideFields.includes("submit") && (
          <Group justify="center">
            <Button variant="gradient" type="submit" mt="xl" size="md">
              Submit
            </Button>
          </Group>
        )}
      </form>
    </>
  );
};

export default BookingForm;
