import { Navigate } from "react-router-dom";
import AdventuresPage from "../adventures/pages/AdventuresPage";
import AddViewEditAdventure from "../adventures/containers/AddViewEditAdventure";

import MotherCover from "../pages/MotherCover/MotherCover";
import MasterCover from "../pages/MasterCover/MasterCover";
import CustomizeCover from "../pages/CustomizeCover/CustomizeCover";

import AddMotherCover from "../containers/AddMotherCover/AddMotherCover";
import AddMasterCover from "../containers/AddMasterCover/AddMasterCover";
import AddCustomizeCover from "../containers/AddCustomizeCover/AddCustomizeCover";

import ViewEditMotherCover from "../containers/ViewEditMotherCover/ViewEditMotherCover";
import ViewEditMasterCover from "../containers/ViewEditMasterCover/ViewEditMasterCover";
import ViewEditCustomizeCover from "../containers/ViewEditCustomizeCover/ViewEditCustomizeCover";

import CoverPricing from "../pricing/pages/CoverPricing";

import Benefits from "../benefits/pages/Benefits/Benefits";
import CoverBenefits from "../benefits/pages/CoverBenefits/CoverBenefits";

const CoverRoutes = [
  {
    path: "cover-api",
    children: [
      { path: "", element: <Navigate to="adventures" replace /> },
      { path: "adventures", element: <AdventuresPage /> },
      { path: "adventures/:action/:id?", element: <AddViewEditAdventure /> },

      // Mother Covers
      {
        path: "mother-covers",
        children: [
          { path: "", element: <MotherCover /> },
          { path: "create", element: <AddMotherCover /> },
          { path: ":id", element: <ViewEditMotherCover mode={"view"} /> },
          { path: ":id/edit", element: <ViewEditMotherCover mode={"edit"} /> },
        ],
      },

      // Master Covers
      {
        path: "master-covers",
        children: [
          { path: "", element: <MasterCover /> },
          { path: "create", element: <AddMasterCover /> },
          { path: ":id", element: <ViewEditMasterCover mode={"view"} /> },
          { path: "edit/:id", element: <ViewEditMasterCover mode={"edit"} /> },
        ],
      },

      // Customize Covers
      {
        path: "customize-covers",
        children: [
          { path: "", element: <CustomizeCover /> },
          { path: "create", element: <AddCustomizeCover /> },
          { path: ":id", element: <ViewEditCustomizeCover mode={"view"} /> },
          {
            path: "edit/:id/",
            element: <ViewEditCustomizeCover mode={"edit"} />,
          },
        ],
      },

      // Cover Benefits
      {
        path: ":planType/:coverID/benefits",
        children: [
          { path: "", element: <CoverBenefits mode={"add"} /> },
          { path: "edit", element: <CoverBenefits mode={"edit"} /> },
        ],
      },

      // Pricing
      {
        path: ":planType/:parentId/pricing",
        children: [
          { path: "", element: <CoverPricing mode={"add"} /> },
          { path: "edit", element: <CoverPricing mode={"edit"} /> },
        ],
      },

      // Cover Benefits
      { path: "benefits", element: <Benefits /> },
    ],
  },
];

export default CoverRoutes;
