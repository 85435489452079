// import UsersPage from "../pages/Users/Users";
// import UserPage from "../containers/User/User";
// import AddUsersPage from "../containers/AddUsers/AddUsersPage";
// import UserLayout from "../layout/UserLayout";
// import Wallet from "../pages/Wallet/Wallet";
// import AssignCover from "../pages/AssignCover/AssignCover";
// import UserPayments from "../pages/Payments/Payments";
// import UserIssuedPolicies from "../pages/UserIssuedPolicies/UserIssuedPolicies";
// import TripDetails from "../../issuance/containers/TripTravellerDetail/TripDetails";

// const isSuperuser = true;

// // Common Routes for both superuser & non-superuser
// const commonRoutes = [
//   { path: "user-profile", element: <UserPage mode={"edit"} /> },
//   { path: "wallet", element: <Wallet /> },
//   { path: "assign-covers", element: <AssignCover /> },
//   { path: "payments", element: <UserPayments /> },
//   { path: "policies-issued", element: <UserIssuedPolicies /> },
//   { path: "policies-issued/:id", element: <TripDetails /> },
// ];

// // Helper function to generate routes base on user role
// const withLayout = (children) => {
//   if (isSuperuser) {
//     // Superuser: Wrap with UserLayout and include :id in the path
//     return [{ path: "user/users/:id/", element: <UserLayout />, children }];
//   } else {
//     // Non-superuser: Directly return the flattened routes without :id
//     return children.map((route) => ({
//       ...route,
//       path: `${route.path}`,
//     }));
//   }
// };

// // Main user routes
// const UserRoutes = [
//   { path: "user/users", element: <UsersPage /> },
//   { path: "user/users/create", element: <AddUsersPage /> },
//   ...withLayout(commonRoutes),
// ];

// export default UserRoutes;


import UsersPage from "../pages/Users/Users";
import UserPage from "../containers/User/User";
import AddUsersPage from "../containers/AddUsers/AddUsersPage";
import UserLayout from "../layout/UserLayout";
import Wallet from "../pages/Wallet/Wallet";
import AssignCover from "../pages/AssignCover/AssignCover";
import UserPayments from "../pages/Payments/Payments";
import UserIssuedPolicies from "../pages/UserIssuedPolicies/UserIssuedPolicies";
import TripDetails from "../../issuance/containers/TripTravellerDetail/TripDetails";

const isSuperuser = true;

// Common Routes for both superuser & non-superuser
const commonRoutes = [
  { path: "user-profile", element: <UserPage mode={"edit"} /> },
  { path: "wallet", element: <Wallet /> },
  { path: "assign-covers", element: <AssignCover /> },
  { path: "payments", element: <UserPayments /> },
  { path: "policies-issued", element: <UserIssuedPolicies /> },
  { path: "policies-issued/:id", element: <TripDetails /> },
];

// Helper function to generate routes base on user role
const withLayout = (children) => {
  if (isSuperuser) {
    // Superuser: Wrap with UserLayout and include :id in the path
    return [{ path: "user/users/:id/", element: <UserLayout />, children }];
  } else {
    // Non-superuser: Directly return the flattened routes without :id
    return children.map((route) => ({
      ...route,
      path: `${route.path}`,
    }));
  }
};

// Main user routes
const UserRoutes = [
  { path: "user/users", element: <UsersPage /> },
  { path: "user/users/create", element: <AddUsersPage /> },
  ...withLayout(commonRoutes),
];

export default UserRoutes;
