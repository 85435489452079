import StaticEmailTemplates from "../container/StaticEmailTemplates/StaticEmailTemplates";
import ComposeMail from "../container/ComposeMail/ComposeMail";
import EmailLogs from "../container/EmailLogs/EmailLogs";

const BulkMailRoutes = [
  {
    path: "bulk-email",
    children: [
        { path: "static-email", element: <StaticEmailTemplates /> },
        { path: "compose-email", element: <ComposeMail /> },
        { path: "emaillogs", element: <EmailLogs /> },
    ],
  },
];

export default BulkMailRoutes;
