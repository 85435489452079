import { SimpleGrid, Group, Select, Button, TextInput, Title } from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import { DateInput } from '@mantine/dates';
import classes from "./AddUser.module.css";

const AddUserForm = ({ onSubmit, groups, departments }) => {
  const form = useForm({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      department_name: "",
      date_of_birth: "",
      gender: "",
      address: "",
      state: "",
      city: "",
      pin: "",
      country: "",
      tel_no: "",
      role: "",
    },

    validate: {
      email: isEmail("Invalid email"),
    },
  });

  return (
    <div className={classes.addUserForm}>
    <Title mb="lg" className={classes.heading}>Basic Info</Title>
      <form
        className={classes.loginForm}
        onSubmit={form.onSubmit(() => {
          onSubmit(form.values);
        })}>
      
        
      <SimpleGrid cols={{ base: 1, xs: 2, sm: 2, lg: 2 }} spacing={{ base: 10, xs: "xl", sm: "xl" }}  className={classes.operatorProfileForm}>
          <TextInput
            placeholder={"Enter user first name"}
            label={"First name"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("first_name")}
          />

          <TextInput
            placeholder={"Enter user last name"}
            label={"Last name"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("last_name")}
          />

          <TextInput
            placeholder={"Enter user email"}
            label={"Email"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("email")}
          />
  
          <DateInput
            placeholder="Enter user date of birth"
            label="Date of Birth"
            size="sm"
            value={form.values.date_of_birth} 
            onChange={(value) => form.setValue("date_of_birth", value)} 
            {...form.getInputProps("date_of_birth")}
          />

          <Select
            label="Gender"
            data={["Male", "Female", "Other"]}
            placeholder="Select gender"
          />
          <TextInput
            placeholder={"Enter user phone number"}
            label={"Phone No"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("tel_no")}
          />
          <TextInput
            placeholder={"Enter user address"}
            label={"Address"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("address")}
          />
          <TextInput
            placeholder={"Enter user pin"}
            label={"Pin"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("pin")}
          />
          <TextInput
            placeholder={"Enter user city"}
            label={"City"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("city")}
          />
          <TextInput
            placeholder={"Enter user state"}
            label={"State"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("state")}
          /> 
          <Select
            label="Department"
            placeholder="Select department"
            data={departments}
            {...form.getInputProps("department_name")}
          />
          <Select
            label="Roles"
            placeholder="Select user roles"
            data={groups}
            {...form.getInputProps("role")}
          />
          {/* <Select
            label="Roles"
            placeholder="Select user roles"
            data={groups}
            value={form.values.role}
            onChange={(value) => form.setFieldValue("role", value)} 
          /> */}
        </SimpleGrid>
      
        <Group justify="center" className={classes.buttonWraper}>
          <Button variant="orange" type="submit" size="md" >
            Submit
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default AddUserForm;
