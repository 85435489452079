import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./Sidebar.module.css";

const arrowRight = "/images/right-arrow.png";
const arrowDown = "/images/downicon.png";
const Sidebar = () => {
  const permittedPages = useSelector((state) => state.auth.permission) || [];
  const permittedUrls = new Set(permittedPages.map((p) => p.modulePageUrl));
  const userRoles = useSelector((state) => state.auth.role) || [];
  

  const isIssuanceTeam = userRoles.includes("Issuance Team");
  const isOperator = userRoles.includes("Operator");

  const iconMapping = {
    Dashboard: "/images/svg/dashboard.svg",
    Covers: "/images/svg/plan.svg",
    "Master Wallet": "/images/svg/wallets.svg",
    Vendors: "/images/svg/vendor.svg",
    Operators: "/images/svg/Operators.svg",
    Payments: "/images/svg/payment.svg",
    Issuance: "/images/svg/issuance.svg",
    User: "/images/svg/user.svg",
    Wallet: "/images/svg/wallets.svg",
    "Assign Plans": "/images/svg/plan.svg",
    "Cover": "/images/svg/plan.svg",
    Commission: "/images/svg/commission.svg",
    Policies: "/images/svg/plan.svg",
    "User Wallets": "/images/svg/wallets.svg",
    Website: "/images/svg/issuance.svg",
    "Sales Management": "/images/svg/issuance.svg",
    "Bulk Email": "/images/svg/issuance.svg",
    "All Policy": "/images/svg/issuance.svg",
  };

  
  const menuItems = [
    { label: "Dashboard", link: "/dashboard" ,},
    {
      label: "Cover",
    
      link: "/cover-api",
      subMenu: [
        { label: "Adventures", link: "/cover-api/adventures" },
        { label: "Benefits", link: "/cover-api/benefits" },
        { label: "Mother Covers", link: "/cover-api/mother-covers" },
        { label: "Master Covers", link: "/cover-api/master-covers" },
        { label: "Customize Covers", link: "/cover-api/customize-covers" },
        // { label: "Pricing", link: "/cover-api/:planType/:parentId/pricing" },
      ],
    },
    { label: "Master Wallet", link: "/asc-wallet/master-wallet" },
    { label: "User Wallets",
      link: "/asc-wallet",
      subMenu: [
        { label: "User Wallet", link: "/asc-wallet/user-wallets" },
        { label: "Wallet Transaction", link: "/asc-wallet/wallet-transactions" },
      ],
    },
    {
      label: "User",
      link: "/user/users",
      subMenu: isIssuanceTeam
        ? [
            { label: "Wallet", link: "/user/users/wallet/wallet" },
            { label: "Assign Cover", link: "/user/users/assign-covers/assign-covers" },
          ]
        : [],
    },
    {
      label: "Vendors",
      link: "/vendor",
    },
    {
      label: "Operators",
      link: "/operator/operators",
      subMenu: isOperator
        ? [      
            { label: "Active Covers", link: "/operator/operators/operator-assign-covers" },
            { label: "Commission", link: "/operator/operators/commission" },
            { label: "Payments", link: "/operator/operators/operator-payments" },
            { label: "Wallet", link: "/operator/operators/wallet" },
            // { label: "Policies Issued", link: "/operator/operators/policies-issued" },     
          ]
        : [],
    },    
    {
      label: "Payments",
      link: "/asc-wallet/payments",
    },
    {
      label: "Issuance",
      link: "/issuance",
      subMenu: [
        { label: "Single Issuance", link: "/issuance/single" },
        { label: "Bulk Issuance", link: "/issuance/bulk" },
        { label: "User Specific Policy", link: "/issuance/user-specific-policy" },
        { label: "Quote Links", link: "/issuance/quote-links" },
      ],
    },  
    { label: "Sales Management", link: "/policies/asc-booking" },
    {
      label: "Website",
      link: "/website",
      subMenu: [
        { label: "Cover Enquiries", link: "/website/cover-enquiries" },
        { label: "Payments", link: "/website/website-payments" },
        { label: "Promotions", link: "/website/promotions" },
        { label: "FAQs", link: "/website/faqs" },
      ],
    },
    {
      label: "All Policy",
      link: "/issuance-policies",
      subMenu: [
        { label: "Trips", link: "/issuance/all-policy" },
        { label: "Travellers", link: "/issuance/all-travelers" },
      ],
    },
    // {
    //   label: "Bulk Email",
    //   link: "/bulk-email",
    //   subMenu: [
    //     { label: "Static Email Templates", link: "/bulk-email/static-email" },
    //     { label: "Compose Email", link: "/bulk-email/compose-email" },
    //     { label: "Email Logs", link: "/bulk-email/emaillogs" },
    //   ],
    // },
  ];

  // State to track open submenu
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const toggleSubMenu = (label) => {
    setOpenSubMenu(openSubMenu === label ? null : label);
  };

  const isPermitted = (link) =>
    [...permittedUrls].some((permitted) => link.includes(permitted));

  const filteredMenuItems = menuItems
    .map((item) => {
      const mainPermitted = isPermitted(item.link);
      if (mainPermitted || (item.subMenu?.some((sub) => isPermitted(sub.link)))) {
        return {
          ...item,
          subMenu: item.subMenu?.filter((sub) => isPermitted(sub.link)) || [],
        };
      }
      return null;
    })
    .filter(Boolean);
 

  return (
     <div className={classes.sidebar}>
      <ul className={classes.sidebarList}>
        {filteredMenuItems.map((item) => (
          <li key={item.link} className={classes.sidebarItem}>
            {item.subMenu.length ? (
              <>
                <button className={classes.sidebarButton} onClick={() => toggleSubMenu(item.label)}>
                  <img src={iconMapping[item.label]} alt={item.label} className={classes.sidebarIcon} />
                  {item.label}
                  <img src={openSubMenu === item.label ? arrowDown : arrowRight} alt="Toggle" className={classes.arrowIcon} />
                </button>
                {openSubMenu === item.label && (
                  <ul className={classes.sidebarSublist}>
                    {item.subMenu.map((sub) => (
                      <li key={sub.link} className={classes.sidebarSubitem}>
                        <NavLink to={sub.link} className={({ isActive }) => (isActive ? `${classes.sidebarLink} ${classes.active}` : classes.sidebarLink)}>
                          {sub.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <NavLink to={item.link} className={({ isActive }) => (isActive ? `${classes.sidebarLink} ${classes.active}` : classes.sidebarLink)}>
                <img src={iconMapping[item.label]} alt={item.label} className={classes.sidebarIcon} />
                {item.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
    
  );
};

export default Sidebar;