import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Tabs from "../../../common/components/Tabs/Tabs";
import MainLoader from "../../../common/components/Loader/Loader";

const endpoint = "operator/operator-profiles/";

const OperatorLayout = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location.pathname.split("/").pop()
  );

  const {
    data: operator,
    error: operatorError,
    isLoading: isOperatorLoading,
    mutate: mutateOperator,
  } = useSWR(`${endpoint}${uuid}`, fetcher, { dedupingInterval: 60000 });

  const handleNavigation = (value) => {
    setCurrentTab(value);
    navigate(`/operator/operators/${uuid}/${value}`);
  };

  const tabsConfig = [
    {
      value: "operator-profile",
      label: "Profile",
      icon: "/images/svg/profile.svg",
      roles: ["is_superuser", "Operator"],
    },
    {
      value: "operator-assign-covers",
      label: "Covers",
      icon: "/images/svg/covers.svg",
      roles: ["is_superuser"],
    },
    {
      value: "wallet",
      label: "Wallet",
      icon: "/images/svg/wallet.svg",
      roles: ["is_superuser", "Operator"],
    },
    {
      value: "commission",
      label: "Commission",
      icon: "/images/svg/comission.svg",
      roles: ["is_superuser", "Operator"],
    },
    {
      value: "policies-issued",
      label: "Policies Issued",
      icon: "/images/svg/comission.svg",
      roles: ["is_superuser", "Operator"],
    },
    {
      value: "operator-payments",
      label: "Payments",
      icon: "/images/svg/comission.svg",
      roles: ["is_superuser"],
    },
  ];

  useEffect(() => {
    setCurrentTab(location.pathname.split("/").pop());
  }, [location]);

  if (isOperatorLoading) {
    return <MainLoader />;
  }

  if (operatorError) {
    return <p>Something went wrong</p>;
  }

  return (
    <div>
      <Tabs
        currentTab={currentTab}
        onChange={handleNavigation}
        tabs={tabsConfig}
      >
        <Outlet context={{ operator, operatorError, mutateOperator }} />
      </Tabs>
    </div>
  );
};

export default OperatorLayout;
