import { useState } from "react";
import { Table, Loader, Paper, ScrollArea, TextInput, ActionIcon, Modal, Flex, Button, Select, Tooltip } from "@mantine/core";
import { IconEdit, IconCheck, IconX, IconFileText, IconLink } from "@tabler/icons-react";
import { fetcher } from "../../../../services/fetcher";
import useSWR, { mutate } from "swr";
import { errorNotifications, successNotifications } from "../../../../utils/handleNotifications";
import { useSelector } from "react-redux"; 
import { useNavigate } from "react-router-dom";
import {
  BookingPriority,
  PaymentStatus,
  BookingReceived,
} from "../../utils/bookingOptions";
import classes from "./Bookings.module.css";

const endpoint = "asc-booking/bookings/";

const Bookings = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR(endpoint, fetcher);
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [modalOpened, setModalOpened] = useState(false);
  
  // Get user role from Redux store
  const userRole = useSelector((state) => state.auth.role);

  if (isLoading) {
    return <Loader />;
  }

  // Enable edit mode & pre-fill data
  const handleEditClick = (row) => {
    setEditingRow(row.id);
    setEditedData(row);
  };

  // Handle input change
  const handleInputChange = (field, value) => {
    setEditedData((prev) => ({ ...prev, [field]: value }));
  };

  // Save changes to backend
  const handleSave = async () => {
    try {
      await fetcher(`${endpoint}${editedData.id}/`, "PATCH", editedData);
      mutate(endpoint);
      successNotifications("Booking updated successfully!");
      setEditingRow(null);
    } catch (error) {
      errorNotifications(error.response?.data || "Update failed", error);
    }
  };

  return (
    <>
        <Flex mih={50} gap="md" justify="end" align="center" direction="row" wrap="wrap" mb="lg">
            <Button
                variant="outline"
                onClick={() => {
                setModalOpened(true);
              }}
            >
              + Create Booking
            </Button>
        </Flex>
        <Paper shadow="sm" p="md">
            <Modal 
              opened={modalOpened} 
              onClose={() => setModalOpened(false)} 
              title={<span style={{ fontWeight: "bold" }}>Create Booking</span>}
              size="lg" 
              centered
            >
            <Flex justify="center" direction="column" align="center" gap="lg">
            <Flex gap="md">
                <Button 
                  variant="light" 
                  color="orange" 
                  leftSection={<IconLink />} 
                  onClick={() => navigate("/policies/asc-booking/generate-link")}
                >
                  Single Booking
                </Button>
                <Button 
                  variant="light" 
                  color="orange" 
                  leftSection={<IconFileText />} 
                  onClick={() => navigate("/policies/asc-booking/bulk-booking")}
              >
                Bulk Booking
                </Button>
              </Flex>
            </Flex>
          </Modal>
          <ScrollArea style={{ width: "100%", overflowX: "auto" }}>
          <div style={{ minWidth: "2200px" }}>
            <Table striped highlightOnHover withBorder withColumnBorders style={{ tableLayout: "auto", minWidth: "1500px" }}>
              <thead>
                <tr>
                  <th className={classes.heading}>Booking No.</th>
                  <th className={classes.heading}>Booking Status</th>
                  <th className={classes.heading}>Priority</th>
                  <th className={classes.heading}>Payment Status</th>
                  <th className={classes.heading}>Booking Received</th>
                  <th className={classes.heading}>Plan Name</th>
                  <th className={classes.heading}>Sales Agent</th>
                  <th className={classes.heading}>Issuance Representative</th>
                  <th className={classes.heading}>Client/Operator</th>
                  <th className={classes.heading}>Start Date</th>
                  <th className={classes.heading}>End Date</th>
                  <th className={classes.heading}>Event Location</th>
                  <th className={classes.heading}>Pax</th>
                  <th className={classes.heading}>Days</th>
                  <th className={classes.heading}>Premium Amount</th>
                  <th className={classes.heading}>Discount</th>
                  <th className={classes.heading}>Price</th>
                  <th className={classes.heading}>Document Status</th>
                  <th className={classes.remarks}>Remarks</th>
                  <th className={classes.heading}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row) => (
                  <tr key={row.id}>
                    {/* Non-editable Fields */}
                    <td className={classes.bookingNo}>{row.booking_no}</td>
                    <td className={classes.text}>
                      {(userRole.includes("Issuance Team") || userRole.includes("Admin")) && editingRow === row.id ? (
                        <TextInput value={editedData.booking_status} onChange={(e) => handleInputChange("booking_status", e.target.value)} />
                      ) : (
                        row.booking_status
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <Select
                          value={editedData.priority}
                          onChange={(value) => handleInputChange("priority", value)}
                          data={BookingPriority}
                        />
                      ) : (
                        row.priority
                      )}
                    </td>
                    <td className={classes.text}>
                      {(userRole.includes("Issuance Team") || userRole.includes("Admin")) && editingRow === row.id ? (
                        <Select
                          value={editedData.payment_status}
                          onChange={(value) => handleInputChange("payment_status", value)}
                          data={PaymentStatus}
                        />
                      ) : (
                        row.payment_status
                      )}
                    </td>
                    <td className={classes.text}>
                      {(userRole.includes("Sales Team") || userRole.includes("Admin")) && editingRow === row.id ? (
                        <Select
                          value={editedData.booking_received}
                          onChange={(value) => handleInputChange("booking_received", value)}
                          data={BookingReceived}
                        />
                      ) : (
                        row.booking_received
                      )}
                    </td>
                    <td className={classes.turncatetext}>
                      <Tooltip label={row.plan_name} withArrow>
                        <span>{row.plan_name}</span>
                      </Tooltip>
                    </td>
                    <td className={classes.text}>{row.sales_agent_name}</td>
                    <td className={classes.text}>{row.issuance_representative_name}</td>
                    <td className={classes.text}>
                      {(userRole.includes("Issuance Team") || userRole.includes("Admin")) && editingRow === row.id ? (
                        <TextInput value={editedData.client_or_operator} onChange={(e) => handleInputChange("client_or_operator", e.target.value)} />
                      ) : (
                        row.client_or_operator
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.start_date} onChange={(e) => handleInputChange("from", e.target.value)} />
                      ) : (
                        row.from
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.end_date} onChange={(e) => handleInputChange("to", e.target.value)} />
                      ) : (
                        row.to
                      )}
                    </td>
                    <td className={classes.text}>
                      {row.event_location}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.pax} onChange={(e) => handleInputChange("pax", e.target.value)} />
                      ) : (
                        row.pax
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.days} onChange={(e) => handleInputChange("days", e.target.value)} />
                      ) : (
                        row.days
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.premium_amount} onChange={(e) => handleInputChange("premium_amount", e.target.value)} />
                      ) : (
                        row.premium_amount
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.discount} onChange={(e) => handleInputChange("discount", e.target.value)} />
                      ) : (
                        row.discount
                      )}
                    </td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <TextInput value={editedData.price} onChange={(e) => handleInputChange("price", e.target.value)} />
                      ) : (
                        row.price
                      )}
                    </td>
                    <td className={classes.text}>
                      {(userRole.includes("Issuance Team") || userRole.includes("Admin")) && editingRow === row.id ? (
                        <TextInput value={editedData.document_status} onChange={(e) => handleInputChange("document_status", e.target.value)} />
                      ) : (
                        row.document_status
                      )}
                    </td>
                    <td className={classes.text}>{row.remarks}</td>
                    <td className={classes.text}>
                      {editingRow === row.id ? (
                        <>
                          <ActionIcon onClick={handleSave} color="green">
                            <IconCheck size={16} />
                          </ActionIcon>
                          <ActionIcon onClick={() => setEditingRow(null)} color="red">
                            <IconX size={16} />
                          </ActionIcon>
                        </>
                      ) : (
                        <ActionIcon onClick={() => handleEditClick(row)}>
                          <IconEdit size={16} />
                        </ActionIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          </ScrollArea>
        </Paper>
    </>
  );
};

export default Bookings;