import {
  Button,
  SimpleGrid,
  Select,
  TextInput,
  Title,
  Paper,
} from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import classes from "./userForm.module.css";

const UserForm = ({ onSubmit, data, roles, departments, mode }) => {
  const form = useForm({
    initialValues: {
      id: data.id || "",
      email: data.user_profile_info.user.email || "",
      first_name: data.user_profile_info.user.first_name || "",
      last_name: data.user_profile_info.user.last_name || "",
      address: data.user_profile_info.address || "",
      state: data.user_profile_info.state || "",
      city: data.user_profile_info.city || "",
      pin: data.user_profile_info.pin || "",
      tel_no: data.user_profile_info.tel_no || "",
      gender: data.user_profile_info.gender || "",
      date_of_birth: data.user_profile_info.date_of_birth || "",
      department_name: data.department_name || "",
      job_title: data.job_title || "",
      manager_name: data.manager_name || "",
      employment_status: data.employment_status || "",
      date_of_hire: data.date_of_hire || "",
      work_schedule: data.work_schedule || "",
      performance_rating: data.performance_rating || "",
      pf_number: data.pf_number || "",
      roles: data.role || [],
    },

    validate: {
      email: isEmail("Invalid email"),
    },
  });

  const isEditMode = mode === "edit";

  return (
    <form
      className={classes.userForm}
      onSubmit={form.onSubmit(() => {
        onSubmit(form.values);
      })}
    >
      <Paper shadow="xl" className={classes.userFormPaper}>
        <Title className={classes.heading}>Employee Details</Title>
        <SimpleGrid
          mt="lg"
          cols={{ base: 1, sm: 2, lg: 2, xl: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.userProfile}
        >
          <TextInput
            placeholder={"Enter job title"}
            label={"Job Title"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("job_title")}
          />

          <Select
            label="Department"
            placeholder="Select department"
            data={departments}
            {...form.getInputProps("department_name")}
            disabled={!isEditMode}
          />

          <TextInput
            placeholder={"Enter manager name"}
            label={"Manager Name"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("manager_name")}
          />

          <Select
            label="Employment Status"
            placeholder="Select Employment Status"
            data={["Full-time", "Part-time", "Contract"]}
            {...form.getInputProps("employment_status")}
            disabled={!isEditMode}
          />

          <TextInput
            label="Date of Hire"
            type="date"
            placeholder="Enter date of hire"
            disabled={!isEditMode}
            {...form.getInputProps("date_of_hire")}
          />

          <TextInput
            placeholder={"Enter PF number"}
            label={"PF Number"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("pf_number")}
          />

          <Select
            label="Roles"
            data={roles}
            placeholder="Select user roles"
            disabled={!isEditMode}
            {...form.getInputProps("roles")}
          />
        </SimpleGrid>
        {isEditMode && (
          <div className="button-wrapper" style={{ textAlign: "center" }}>
            <Button variant="outline" type="submit" mt="xl" size="md">
              Save
            </Button>
          </div>
        )}
      </Paper>

      <Paper shadow="xl" mt="xl" className={classes.userFormPaper}>
        <Title className={classes.heading}>Profile Details</Title>
        <SimpleGrid
          mt="lg"
          cols={{ base: 1, sm: 2, lg: 2, xl: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.userProfile}
        >
          <TextInput
            placeholder={"Enter user first name"}
            label={"First name"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("first_name")}
          />

          <TextInput
            placeholder={"Enter user last name"}
            label={"Last name"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("last_name")}
          />

          <TextInput
            placeholder={"Enter user email"}
            label={"Email"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("email")}
          />

          <TextInput
            label="Date of Birth"
            type="date"
            placeholder="Enter date of birth"
            disabled={!isEditMode}
            {...form.getInputProps("date_of_birth")}
          />

          <Select
            label="Gender"
            data={["Male", "Female", "Other"]}
            placeholder="Select gender"
            {...form.getInputProps("gender")}
            disabled={!isEditMode}
          />

          <TextInput
            placeholder={"9898989898"}
            label={"Phone No"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("tel_no")}
          />

          <TextInput
            placeholder={"S-25/221"}
            label={"Address"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("address")}
          />

          <TextInput
            placeholder={"221002"}
            label={"Pin"}
            type={"number"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("pin")}
          />

          <TextInput
            placeholder={"Varanasi"}
            label={"City"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("city")}
          />

          <TextInput
            placeholder={"UP"}
            label={"State"}
            type={"text"}
            size={"sm"}
            disabled={!isEditMode}
            {...form.getInputProps("state")}
          />
        </SimpleGrid>
      </Paper>
    </form>
  );
};

export default UserForm;
