import Bookings from "../pages/Bookings/Bookings";
import SingleBookingForm from "../forms/SingleBookingForm/SingleBookingForm";
import BulkBookingForm from "../forms/BulkBookingForm/BulkBookingForm";

const BookingRoutes = [
  {
    path: "policies",
    children: [
      { path: "asc-booking", element: <Bookings /> },
      // { path: "generate-link", element: <BookingForm /> }, 
      { path: "asc-booking/generate-link", element: <SingleBookingForm /> }, 
      { path: "asc-booking/bulk-booking", element: <BulkBookingForm /> }, 
    ],
  },
];

export default BookingRoutes;
