import Trips from "../pages/Trips/Trips";
import Travellers from "../pages/Travellers/Travellers";
import TripDetails from "../../issuance/containers/TripTravellerDetail/TripDetails";
import TravellerDetails from "../../issuance/containers/TripTravellerDetail/TravellerDetails";

const PoliciesRoutes = [
  {
    path: "issuance",
    children: [
      { path: "all-policy", element: <Trips /> },
      { path: "all-travelers", element: <Travellers />},
      { path: "all-travelers/:id", element: <TravellerDetails /> },
      // { path: "policies/travellers/:id", element: <TravellerDetails /> },
      { path: "all-policy/trip/:id", element: <TripDetails /> }, 
    ],
  },
];

export default PoliciesRoutes;
