import axiosPrivate from "./axiosPrivate";

export const fetcher = async (
  url,
  method = "GET",
  data = null,
  headers = {},
  options = {}
) => {
  if (!headers || Object.keys(headers).length === 0) {
    headers = { "content-type": "application/json" };
  }

  const config = {
    ...options,
    method,
    headers,
    data,
    responseType: options.responseType || "json",
  };

  const response = await axiosPrivate(url, config);
  return response.data;
};

export const fileFetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.blob();
};



export const uploadFetcher = async (
  url,
  method = "POST",
  data = null,
  headers = {},
  options = {}
) => {
  let finalHeaders = { ...headers };

  let isFormData = data instanceof FormData;

  if (!isFormData) {
    const hasFile = Object.values(data || {}).some(
      (value) => value instanceof File
    );

    if (hasFile) {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        }
         else {
          formData.append(key, value);
        }
      });

      data = formData;
      isFormData = true;
    }
  }

  if (isFormData) {
    delete finalHeaders["Content-Type"];
  } else {
    finalHeaders["Content-Type"] = "application/json";
  }

  const config = {
    ...options,
    method,
    headers: finalHeaders,
    data,
    responseType: options.responseType || "json",
  };

  try {
    const response = await axiosPrivate(url, config);
    return response.data;
  } catch (error) {
    console.error("Upload Failed:", error.response?.data || error.message);
    throw error;
  }
};
