import { useState, useEffect } from "react";
import axiosPrivate from "../../../../services/axiosPrivate";

const useGroupsAndDepartments = () => {
  const [fields, setFields] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoadingFields(true);
        const response = await axiosPrivate.get(
          "auth-api/group-and-department/"
        );
        // console.log("Response data:", response.data);
        setFields(response.data);
        setLoadingFields(false);
      } catch (error) {
        setLoadingFields(false);
      }
    })();
  }, []);

  return { fields, loadingFields };
};

export default useGroupsAndDepartments;
