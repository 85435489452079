import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
// import { useOutletContext } from "react-router-dom";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";
import MainLoader from "../../../../common/components/Loader/Loader";
import { Paper, SimpleGrid, Image, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import classes from "./OperatorWallet.module.css";
import { USER_TYPES } from "../../../../utils/userTypes";
import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";

const walletEndpoint = "asc-wallet/user-wallet";
const addFundEndpoint = "asc-wallet/user-wallet/transactions/";

const OperatorWallet = () => {
  // const outletContext = useOutletContext();

  const { user_type } = useSelector((state) => state.auth);
  const operatorProfile = useSelector((state) => state.operatorProfile);
  // console.log("xxxx", operatorProfile);


  // const operator = outletContext ? outletContext.operator : operatorProfile;
  const operator = operatorProfile;

  // console.log("operator data", operator)



  const email = operator ? operator.operator.email : null;

 
  const transactionEndpoint = `${addFundEndpoint}?user_email=${email}`;

  const walletInfoEndpoint = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${walletEndpoint}?email=${email}`
    : `${walletEndpoint}`;

  const isDev = import.meta.env.MODE === "development";
  
  const operatorLogo = isDev
    ? `${operator?.logo}`
    : operator?.logo;
  
  
  
  // console.log("xxxxxx", operatorLogo);


  const {
    data: wallet,
    // error: walletError,
    isLoading: isWalletLoading,
  } = useSWR(walletInfoEndpoint, fetcher, {
    dedupingInterval: 60000,
  });

  const config = {
    fields: [
      { key: "amount", label: "Amount" },
      { key: "transaction_number", label: "Transaction number" },
      { key: "transaction_type", label: "Transaction type" },
      { key: "created_by", label: "Created by" },
      { key: "created_at", label: "Date", type: "datetime" },
    ],
  };

  if (isWalletLoading) {
    return <MainLoader />;
  }

  // if (walletError || transactionError) {
  //   return <div>Error loading data</div>;
  // }
  console.log("aditi");
  console.log(operatorLogo);
  console.log("aditi");


  return (
    <>
      <Paper my="lg" shadow="xl" className={classes.operatorWallet}>
        <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, lg: 2 }}>
          <div className={classes.operatorInfoContainer}>
            <div className={classes.imageContainer}>
              {operator?.logo && (
                <Image
                  src={operatorLogo}
                  alt="Operator Logo"
                  // height={40}
                  className={classes.operatorLogo}
                />
              )}
            </div>
            <Text className={classes.operatorName}>
              {operator?.operator_type}
            </Text>
            <Text className={classes.operatorPhno}>
              <Image
                src="/images/svg/call.svg"
                className={classes.callIcon}
                alt="avatar"
              />
              {operator?.operator?.phone_number}
            </Text>
            <Text className={classes.operatorEmail}>
              <Image
                src="/images/svg/mail-icon.svg"
                className={classes.mailIcon}
                alt="avtar"
              />
              {operator?.operator?.email}
            </Text>
            <Text className={classes.operatorcode}>
              <span className={classes.codeHeading}>Operator Code :</span>
              {operator?.operator_unique_code}
            </Text>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.walletbox}>
              <WalletCard
                isLoading={isWalletLoading}
                balance={wallet.balance}
                type={wallet.currency_type}
              />
            </div>
          </div>
        </SimpleGrid>
      </Paper>

      <DataWrapper endpoint={transactionEndpoint} config={config} />
    </>
  );
};

export default OperatorWallet;
