import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import authReducer from "./features/auth/authSlice";
import userProfileReducer from "./features/auth/userProfileSlice";
import operatorProfileReducer from "./features/auth/operatorProfileSlice";

const persistConfig = {
  key: "auth",
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  userProfile: userProfileReducer,
  operatorProfile: operatorProfileReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  // devTools: import.meta.env.MODE === "development" ? true : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
