import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./features/auth/pages/Login/Login";
import Unauthorized from "./common/pages/Unauthorized/Unauthorized";
import Layout from "./common/templates/Layout/Layout";
import RequireAuth from "./features/auth/components/RequireAuth/RequireAuth";
import PersistLogin from "./features/auth/components/PersistLogin/PersistLogin";
import Dashboard from "./features/dashboard/pages/Dashboard";
import AccountPage from "./features/users/pages/Account/Account";
import HandleChangePassword from "./features/users/pages/Account/Handlechangepassword";

import routes from "./routes/routes";

function App() {
  const router = createBrowserRouter([
    // Public routes
    { path: "/", element: <LoginPage /> },
    { path: "unauthorized", element: <Unauthorized /> },

    // Private routes
    {
      element: <PersistLogin />,
      children: [
        {
          element: <Layout />,
          children: [
            {
              element: <RequireAuth />,
              children: [
                ...routes,
                { path: "dashboard", element: <Dashboard /> },
                { path: "profile", element: <AccountPage /> },
                { path: "change-password", element: <HandleChangePassword /> },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
