import UserWallets from "../pages/UserWallets/UserWallets";
import WalletTransactions from "../pages/WalletTransactions/WalletTransactions";


const UserWalletRoutes = [
  {
    path: "asc-wallet",
    children: [
        { path: "user-wallets", element: <UserWallets /> },
        { path: "wallet-transactions", element: <WalletTransactions /> },
    ],
  },
];

export default UserWalletRoutes;
