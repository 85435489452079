import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { Flex, Button, Image, Paper, Box, Center } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import DataTable from "../../../../common/components/DataTable/DataTable";
import WithPermission from "../../../auth/components/WithPermission/WithPermission";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import MainLoader from "../../../../common/components/Loader/Loader";
import { USER_TYPES } from "../../../../utils/userTypes";

const assignPlan = "issuance/assign-plan/";
const coversEndpoint = "issuance/covers-and-customize-covers/";
const removePlanEndpoint = "issuance/user-cover-plan-access/remove/";

const AssignCoverOperator = () => {
  const outletContext = useOutletContext();
  const operator = outletContext ? outletContext.operator : null;
  const { user_type } = useSelector((state) => state.auth);
  const userRoles = useSelector((state) => state.auth.role);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [removalSelection, setRemovalSelection] = useState([]); 

  console.log(userRoles,'userRoles');

  const [value, toggle] = useToggle(["add", "view"]);

  const operatorEmail = operator ? operator.operator.email : null;
  const assignPlanUrl = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${assignPlan}?user_email=${operatorEmail}`
    : assignPlan;

  const {
    data: plans,
    error: plansError,
    isLoading: isPlansLoading,
    mutate: mutatePlans,
  } = useSWR(assignPlanUrl, fetcher);

  const {
    data: covers,
    error: coversError,
    isLoading: isCoversLoading,
  } = useSWR(plans ? coversEndpoint : null, fetcher, {
    dedupingInterval: 9000,
  });

  const plansConfig = {
    fields: [
      { key: "title", label: "Name" },
      { key: "cover_scope", label: "Scope" },
      { key: "currency", label: "Currency" },
      { key: "price", label: "Price" },
    ],
    selectionCheckbox: true, 
  };

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleRemovalSelection = (newSelection) => {
    setRemovalSelection(newSelection);
  };

  const handleAssignCover = async () => {
    if (currentSelection.length === 0) {
      return successNotifications("Please select covers");
    }

    try {
      const customizeCoverIds = [];
      currentSelection.forEach((id) => {
        if (covers.customize_covers.some((customizeCover) => customizeCover.id === id)) {
          customizeCoverIds.push(id);
        }
      });

      const currentCustomize = Array.isArray(plans.customize_covers)
        ? plans.customize_covers.map((customize) => customize.id)
        : [];

      const payload = {
        user_email: operator.operator.email,
        customize_cover_ids: [...currentCustomize, ...customizeCoverIds],
      };

      await fetcher("issuance/assign-plan/", "POST", payload);
      await mutatePlans();
      toggle();
      successNotifications("Success, Covers assigned");
    } catch (error) {
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleRemovePlan = async () => {
    if (removalSelection.length === 0) {
      return successNotifications("Please select plans to remove");
    }

    try {
      const payload = {
        user_id: operator.operator.id, 
        cover_ids: removalSelection,
      };

      await fetcher(removePlanEndpoint, "DELETE", payload);
      await mutatePlans(); 
      successNotifications("Success, Plans removed");
      setRemovalSelection([]);
    } catch (error) {
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  if (isPlansLoading) {
    return <MainLoader />;
  }

  if (plansError) {
    return <p>Failed to load plans</p>;
  }

  const assignedCovers = Array.isArray(plans?.customize_covers) ? plans.customize_covers : [];
  const combinedData = covers
    ? covers.customize_covers.filter((cover) => !assignedCovers.some((assignedCover) => assignedCover.id === cover.id))
    : [];
  
  const isPlansEmpty = assignedCovers.length === 0;

  const EmptyComponent = () => (
    <Paper shadow="xl" style={{ backgroundColor: "#fff" }}>
      <Box style={{ textAlign: "center", padding: "20vh" }}>
        <Center>
          <Image src="/images/add-icon.png" width={80} height={114} alt="avatar" />
        </Center>
        <p>No covers available.</p>
        <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
        </WithPermission>
      </Box>
    </Paper>
  );

  return (
    <>
      <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
        <Flex
          mih={50}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"lg"}
        >
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
          <Button variant="outline" onClick={handleRemovePlan} disabled={removalSelection.length === 0}>
            - Remove Plan
          </Button>
        </Flex>
      </WithPermission>

      {value === "add" ? (
        isPlansEmpty ? (
          <EmptyComponent />
        ) : (
          <DataTable
            config={plansConfig}
            data={assignedCovers}
            heading={"Assigned Covers"}
            onSelectionChange={handleRemovalSelection} 
          />
        )
      ) : (
        <DataTable
          config={{
            ...plansConfig,
            selectionActions: [{ label: "Save", handler: handleAssignCover }],
          }}
          data={coversError ? [] : combinedData}
          heading={"Select Covers"}
          onSelectionChange={handleSelection}
          loading={isCoversLoading}
        />
      )}
    </>
  );
};

export default AssignCoverOperator;