import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";

const RequireAuth = () => {
  const location = useLocation();
  const { isAuthenticated, permittedPages } = useSelector(
    createSelector(
      [(state) => state.auth.isAuthenticated, (state) => state.auth.permission],
      (isAuthenticated, permittedPages) => ({
        isAuthenticated,
        permittedPages,
      })
    )
  );

  // console.log("RequireAuth Component Loaded");
  // console.log("isAuthenticated:", isAuthenticated);
  // console.log("Current Path:", location.pathname);
  // console.log("Redux Permitted Pages:", permittedPages);
  // console.log("Expected ModulePage URLs:", permittedPages.map(p => p.modulePageUrl));
  // console.log("Does it match?", permittedPages.some(p => p.modulePageUrl === location.pathname));
  


  // Enhanced Page Access Check with More Precise Matching
  const checkPageAccess = useMemo(() => {
    return (path, permittedPages) => {
      const pathSegments = path.split("/").filter(Boolean);
      let authResult = {
        isUserAuthorised: false,
        allowedActions: [],
        unauthorizedReason: "No matching permissions found",
      };

      // Prevent excessive route nesting
      const MAX_ROUTE_DEPTH = 5; // Increased to support submodule routes
      if (pathSegments.length > MAX_ROUTE_DEPTH) {
        return {
          ...authResult,
          unauthorizedReason: `Route depth exceeds maximum allowed (${MAX_ROUTE_DEPTH})`,
        };
      }

      // Enhanced ID validation
      const isValidDynamicSegment = (segment) => {
        // Numeric ID check (positive integers)
        const isNumericId = /^\d+$/.test(segment);

        // UUID v4 validation (most common UUID format)
        const isUuid =
          /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
            segment
          );

        return isNumericId || isUuid;
      };

      const findMatchingModule = (items, isSubmoduleSearch = false) => {
        for (const item of items) {
          const groupSegment = pathSegments[0];
          const moduleSegment = pathSegments[1] || pathSegments[0]; // Handle single-level routes

          // Check group and module match
          const isGroupMatch = groupSegment === item.group;
          const isModuleMatch = moduleSegment === item.modulePageUrl;

          // console.log(`Static module: ${moduleSegment}`);
          // console.log(`Static group: ${groupSegment}`);
          // console.log(`dynamic group: ${item.group}`);
          // console.log(`dynamic module: ${item.modulePageUrl}`);
          // console.log(`Group Match: ${isGroupMatch}, Module Match: ${isModuleMatch}`);


          if (isGroupMatch && isModuleMatch) {
            // Collect all active actions for the module and its submodules
            const collectAllActions = (moduleItem, isSubmodule = false) => {
              // Collect module or submodule actions
              const actions = (moduleItem.actions || [])
                .filter((action) => action.roleIsActive)
                .map((action) => ({
                  ...action,
                  moduleName: moduleItem.modulePage,
                  moduleUrl: moduleItem.modulePageUrl,
                  isSubmodule,
                }));

              // If it's a module, also collect submodule actions
              if (!isSubmodule && moduleItem.submodules) {
                const submoduleActions = moduleItem.submodules.flatMap(
                  (submodule) =>
                    (submodule.actions || [])
                      .filter((action) => action.roleIsActive)
                      .map((action) => ({
                        ...action,
                        moduleName: submodule.modulePage,
                        moduleUrl: submodule.modulePageUrl,
                        isSubmodule: true,
                      }))
                );

                return [...actions, ...submoduleActions];
              }

              return actions;
            };

            // Determine route type and handle accordingly
            const allowedActions = collectAllActions(item, isSubmoduleSearch);

            // Handle different route scenarios
            const handleRouteAuthorization = () => {
              // Submodule route handling
              if (pathSegments.length >= 3 && !isSubmoduleSearch) {
                const submoduleSegment = pathSegments[2];

                // Check for submodule in current module
                const submodules = item.submodules || [];
                const matchingSubmodule = submodules.find(
                  (submodule) => submodule.modulePageUrl === submoduleSegment
                );

                if (matchingSubmodule) {
                  // Recursive call to find matching submodule
                  return findMatchingModule([matchingSubmodule], true);
                }
              }

              // Dynamic ID route
              if (pathSegments.length >= 3) {
                const lastSegment = pathSegments[pathSegments.length - 1];
                const actionSegment = pathSegments[pathSegments.length - 2];

                // Check for ID-based route
                if (isValidDynamicSegment(lastSegment)) {
                  const viewAction = allowedActions.find((action) =>
                    action.action.toLowerCase().includes("view")
                  );

                  if (viewAction) {
                    return {
                      isUserAuthorised: true,
                      allowedActions,
                      unauthorizedReason: "",
                      matchedModule: {
                        modulePageUrl: item.modulePageUrl,
                        moduleName: item.modulePage,
                        modulePageId: item.modulePageId,
                      },
                    };
                  }
                }

                // Check for explicit action matching
                const matchingAction = allowedActions.find(
                  (action) =>
                    ["view", "create", "update", "delete"].some(
                      (predefinedAction) =>
                        action.action.toLowerCase().includes(predefinedAction)
                    ) &&
                    action.actionUrl.toLowerCase() ===
                      actionSegment.toLowerCase()
                );

                if (matchingAction) {
                  return {
                    isUserAuthorised: true,
                    allowedActions,
                    unauthorizedReason: "",
                    matchedModule: {
                      modulePageUrl: item.modulePageUrl,
                      moduleName: item.modulePage,
                      modulePageId: item.modulePageId,
                    },
                  };
                }
              }

              // Base module route
              const viewAction = allowedActions.find((action) =>
                action.action.toLowerCase().includes("view")
              );

              return viewAction
                ? {
                    isUserAuthorised: true,
                    allowedActions,
                    unauthorizedReason: "",
                    matchedModule: {
                      modulePageUrl: item.modulePageUrl,
                      moduleName: item.modulePage,
                      modulePageId: item.modulePageId,
                    },
                  }
                : {
                    isUserAuthorised: false,
                    allowedActions: [],
                    unauthorizedReason: "No view permission for this module",
                  };
            };

            return handleRouteAuthorization();
          }
        }

        return null;
      };

      // Find matching module or return default unauthorized result
      // return findMatchingModule(permittedPages) || authResult;
      return findMatchingModule(permittedPages ?? []) || authResult;

    };
  }, []);

  // Memoized Authorization Check
  // const {
  //   isUserAuthorised,
  //   allowedActions,
  //   unauthorizedReason,
  //   matchedModule,
  //   matchedGroup,
  // } = useMemo(() => {
  //   return checkPageAccess(location.pathname, permittedPages);
  // }, [location.pathname, permittedPages, checkPageAccess]);

  const {
    isUserAuthorised,
    allowedActions,
    unauthorizedReason,
    matchedModule,
    matchedGroup,
  } = useMemo(() => {
    if (!permittedPages || !Array.isArray(permittedPages)) {
      return {
        isUserAuthorised: false,
        allowedActions: [],
        unauthorizedReason: "Permissions not loaded",
        matchedModule: null,
        matchedGroup: null,
      };
    }
    return checkPageAccess(location.pathname, permittedPages);
  }, [location.pathname, permittedPages, checkPageAccess]);
  

  // Comprehensive Unauthorized Handling
  // if (!isAuthenticated) {
  //   return (
  //     <Navigate
  //       to="/login"
  //       state={{
  //         from: location,
  //         reason: "Authentication required",
  //       }}
  //       replace
  //     />
  //   );
  // }
  if (!isAuthenticated || !permittedPages) {
    return <div>Loading...</div>;
  }
  

  if (!isUserAuthorised) {
    console.error("Route Authorization Failed", {
      path: location.pathname,
      reason: unauthorizedReason,
      matchedModule,
      matchedGroup,
    });

    return (
      <Navigate
        to="/unauthorized"
        state={{
          from: location,
          reason: unauthorizedReason,
          matchedModule,
          matchedGroup,
        }}
        replace
      />
    );
  }

  // Pass Enhanced Context to Child Routes
  return (
    <Outlet
      context={{
        allowedActions,
        matchedModule,
        matchedGroup,
      }}
    />
  );
};

export default RequireAuth;