import { Menu, Group, ActionIcon, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import classes from "./Popup.module.css";

export function SplitButton({ userId }) {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Group wrap="nowrap" gap={0}>
      <Menu
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withinPortal
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
            style={{ background: "none" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <circle cx="2" cy="2" r="2" fill="#333333" />
              <circle cx="2" cy="9" r="2" fill="#333333" />
              <circle cx="2" cy="16" r="2" fill="#333333" />
            </svg>
            {/* <Image src="/images/svg/menu-item.svg"/> */}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown className={classes.dropdownBox}>
          <Menu.Item
            className={classes.dropdownItem}
            style={{ width: "150px" }}
            onClick={() => handleNavigation(`${userId}/user-profile`)}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            className={classes.dropdownItem}
            style={{ width: "150px" }}
            onClick={() => handleNavigation(`${userId}/assign-covers`)}
          >
            Covers
          </Menu.Item>
          <Menu.Item
            className={classes.dropdownItem}
            style={{ width: "150px" }}
            onClick={() => handleNavigation(`${userId}/wallet`)}
          >
            Wallet
          </Menu.Item>
          <Menu.Item
            className={classes.dropdownItem}
            style={{ width: "150px" }}
            onClick={() => handleNavigation(`${userId}/policies-issued`)}
          >
            Policy Issued
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

export default SplitButton;
