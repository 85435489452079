import { useState } from "react";
import { Paper, Text, CopyButton, Button, Divider } from "@mantine/core";
import QuoteLinkForm from "../../forms/QuoteLink/QuoteLinkForm";
import { fetcher } from "../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";

const QuoteLink = ({ quoteData, coverOption, isOperator = null, bookingFormData  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  const endpoint = isOperator
    ? `generate-quote/quotes/create/?operator_id=${isOperator.id}`
    : "generate-quote/quotes/create/";

  const handleSubmit = async (data) => {
    console.log("Received Data from Form:", data); // Debugging
  console.log("Received Booking Form Data:", bookingFormData); // Debugging
  console.log("Final Payload Before API Call:", { ...bookingFormData, ...data });

    setIsLoading(true);
    const price = quoteData.total_price[coverOption];
    const cover_pricing_id = quoteData.covers[coverOption][0];

    const formatdata = {
      nationality: quoteData.quote_data.nationality,
      travelling_from: quoteData.quote_data.travelling_from,
      travelling_to: quoteData.quote_data.travelling_to,
      num_people: quoteData.quote_data.age_fields.length,
      adventure: quoteData.quote_data.adventure[0],
      start_date: quoteData.quote_data.start_date,
      end_date: quoteData.quote_data.end_date,
      price: price,
      cover_pricing: cover_pricing_id,
      ...bookingFormData, // Include Booking Form Data
      ...data,
    };

    try {
      const response = await fetcher(endpoint, "POST", formatdata);
      setGeneratedLink(response.unique_link);
      successNotifications("Success! Link Generated");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper p={"lg"} my={"lg"}>
      <Text size={"lg"} fw={600} mb={"xl"}>
        Enter the details of the traveler
      </Text>

      <QuoteLinkForm onSubmit={handleSubmit} isLoading={isLoading} />

      <Divider mt={"xl"} />

      {generatedLink && (
        <CopyButton value={generatedLink}>
          {({ copied, copy }) => (
            <Button
              color={copied ? "teal" : "#999999"}
              onClick={copy}
              mt="xl"
              variant="outline"
              rightSection={<img src="/images/svg/copy.svg" />}
            >
              {copied ? "Copied link" : "Copy link"}
            </Button>
          )}
        </CopyButton>
      )}
    </Paper>
  );
};

export default QuoteLink;
